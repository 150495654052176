import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'mgi-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() className: string;
  public year: number;
  isSupportVisible: boolean = false;
  constructor() {}

  ngOnInit(): void {
    this.year = moment().get('year');
  }

  goPolicyPage() {
    window.open('/#/policy', '_blank');
  }

  goAboutPage() {
    window.open('https://www.mgiverified.com/about', '_blank');
  }

  onSupportClick() {
    this.isSupportVisible = true;
  }

  hideSupportModal() {
    this.isSupportVisible = false;
  }
}
