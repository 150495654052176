import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthActions } from '@actions/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(private router: Router, private store: Store) {}

  handleApiError(error: HttpErrorResponse) {
    let errorCode = error.error?.error?.code;
    if (error.status === 401 && !(errorCode === 'legals_error')) {
      if (
        errorCode === 'legal_prospect_terms_of_service_error' ||
        errorCode === 'legal_referee_terms_of_service_error' ||
        errorCode === 'legal_seller_terms_of_service_error'
      ) {
        return throwError(errorCode);
      } else {
        this.store.dispatch(AuthActions.clearToken());
        this.router.navigate(['login'], {
          queryParams: { returnUrl: this.router.routerState.snapshot.url },
        });
        window.localStorage.setItem(
          'returnUrl',
          this.router.routerState.snapshot.url
        );
        return throwError('Session Timeout');
      }
    }

    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` + `body was: `,
        error.error
      );
    }
    if (error.error) {
      return throwError(error.error);
    }
    return throwError('Something bad happened; please try again later.');
  }
}
