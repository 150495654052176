import { createAction, props } from '@ngrx/store';
import { IProfileOrg, TRoles } from '@reducers/auth.reducer';

export const login = createAction(
  'LOGIN',
  props<{ email: string; pwd: string }>()
);
export const loginSuccess = createAction(
  'LOGIN_SUCCESS',
  props<{ payload: any }>()
);
export const loginError = createAction(
  'LOGIN_ERROR',
  props<{ error: Error }>()
);

export const clearToken = createAction('CLEAR_TOKEN');
export const logout = createAction('LOGOUT', props<{ token: string }>());
export const logoutSuccess = createAction('LOGOUT_SUCCESS');
export const logoutError = createAction(
  'LOGOUT_ERROR',
  props<{ error: Error }>()
);

export const getProfile = createAction(
  '[AUTH] GET_PROFILE',
  props<{ token: string }>()
);
export const reGetProfile = createAction('[AUTH] RE_GET_PROFILE');

export const getProfileSuccess = createAction(
  '[AUTH] GET_PROFILE_SUCCESS',
  props<{ payload: any }>()
);
export const getProfileError = createAction(
  '[AUTH] GET_PROFILE_ERROR',
  props<{ error: Error }>()
);

export const setProfile = createAction(
  '[AUTH] SET_PROFILE',
  props<{ payload: Record<string, any> }>()
);

export const updateProfile = createAction(
  '[AUTH] UPDATE_PROFILE',
  props<{ data: Record<string, any>; keepModal: Boolean }>()
);

export const updateProfileSuccess = createAction(
  '[AUTH] UPDATE_PROFILE_SUCCESS',
  props<{ payload: any }>()
);

export const updateProfileError = createAction(
  '[AUTH] UPDATE_PROFILE_ERROR',
  props<{ error: Error }>()
);

export const showProfileModal = createAction(
  '[AUTH] SHOW_PROFILE_MODAL',
  props<{ isFirstTime: boolean }>()
);

export const hideProfileModal = createAction('[AUTH] HIDE_PROFILE_MODAL');

export const setSelectedOrg = createAction(
  '[AUTH] SET SELECTED ORG',
  props<{ selectedOrgId: string }>()
);

export const sessionCheck = createAction(
  '[AUTH] SESSION_CHECK',
  props<{ token: string }>()
);
export const sessionCheckSuccess = createAction(
  '[AUTH] SESSION_CHECK_SUCCESS',
  props<{ payload: any }>()
);
export const sessionCheckError = createAction(
  '[AUTH] SESSION_CHECK_ERROR',
  props<{ error: Error }>()
);

export const validate = createAction(
  '[AUTH] VALIDATE',
  props<{ token: string }>()
);
export const validateSuccess = createAction(
  '[AUTH] VALIDATE_SUCCESS',
  props<{ payload: any }>()
);
export const validateError = createAction(
  '[AUTH] VALIDATE_ERROR',
  props<{ error: Error }>()
);

export const setSelectedRole = createAction(
  '[AUTH] SET ROLE',
  props<{ role: TRoles }>()
);

export const AuthActions = {
  login,
  loginSuccess,
  loginError,
  sessionCheck,
  sessionCheckSuccess,
  sessionCheckError,
  clearToken,
  logout,
  logoutSuccess,
  logoutError,
  validate,
  validateSuccess,
  validateError,
  getProfile,
  getProfileSuccess,
  getProfileError,
  reGetProfile,
  setProfile,
  updateProfile,
  updateProfileSuccess,
  updateProfileError,
  showProfileModal,
  hideProfileModal,
  setSelectedOrg,
  setSelectedRole,
};
