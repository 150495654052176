import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import {
  NzButtonType,
  NzButtonShape,
  NzButtonSize,
} from 'ng-zorro-antd/button/button.component';

@Component({
  selector: 'mgi-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent implements OnInit {
  @Input() className: string;
  @Input() block = false;
  @Input() htmlType: 'button' | 'submit' | 'reset' = 'button';
  @Input() shape: NzButtonShape = null;
  @Input() size: NzButtonSize = 'default';
  @Input() type: NzButtonType = 'primary';
  @Input() disabled = false;
  @Input() icon: string;
  @Input() loading = false;

  @Output() click = new EventEmitter<any>();

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.renderer.addClass(this.elementRef.nativeElement, 'mgi-button');
  }

  ngOnInit(): void {}

  handleClick(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.click.emit(event);
  }
}
