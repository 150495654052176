import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { selectAuthProfile, selectAuth } from '@store/selectors';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AuthActions } from '@actions/auth.actions';
import { Constants } from '@modules/shared/constants';

@AutoUnsubscribe()
@Component({
  selector: 'mgi-user-profile-modal',
  templateUrl: './user-profile-modal.component.html',
  styleUrls: ['./user-profile-modal.component.scss'],
})
export class UserProfileModalComponent implements OnInit, OnDestroy {
  profile$: Subscription;
  auth$: Subscription;
  fields: FormlyFieldConfig[];
  modalTitle: string;
  profile: any = false;
  isFetching = false;
  isVisible = false;
  hideHeader = true;
  isReferee: boolean = false;
  blockedOrganizations: any = [];
  modalWidth: number = Constants.DEFAULT_MODAL_WIDTH;

  constructor(private store: Store, public translate: TranslateService) {
    this.profile$ = this.store
      .pipe(select(selectAuthProfile))
      .subscribe((profile) => {
        console.log(profile);
        if (
          profile.data.blocked_organizations &&
          profile.data.blocked_organizations.length >= 0
        ) {
          this.blockedOrganizations = [...profile.data.blocked_organizations];
        } else {
          this.blockedOrganizations = [];
        }
        this.isVisible = profile.modal.visible;
        this.isFetching = profile.isFetching;
        if (this.isFetching && this.profile) {
          return;
        }
        this.profile = Object.assign({}, profile.data);
        if (
          profile.modal.isFirstTime ||
          typeof profile.data.auto_approve_prospects === 'undefined'
        ) {
          this.modalTitle = this.translate.instant('PROFILE.GET_SETUP');
          if (typeof profile.data.auto_approve_prospects === 'undefined') {
            this.profile.auto_approve_prospects = true;
          }
        } else {
          this.modalTitle = this.translate.instant('PROFILE.UPDATE_SETTINGS');
        }
      });
    this.fields = this.getFields();

    this.auth$ = this.store.pipe(select(selectAuth)).subscribe((auth) => {
      if (!this.isReferee && auth.isReferee) {
        this.fields = this.getRefereeFields();
      }
      this.isReferee = auth.isReferee;
    });
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {}

  getFields() {
    return [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'flex-fill left',
            key: 'first_name',
            type: 'custominput',
            templateOptions: {
              label: this.translate.instant('ADMIN.USER.FIRST_NAME'),
              placeholder: this.translate.instant('ADMIN.USER.FIRST_NAME'),
              appearance: 'fill',
              required: true,
              icon: 'user',
              hideRequiredMarker: true,
            },
          },
          {
            className: 'flex-fill right',
            key: 'last_name',
            type: 'custominput',
            templateOptions: {
              label: this.translate.instant('ADMIN.USER.LAST_NAME'),
              placeholder: this.translate.instant('ADMIN.USER.LAST_NAME'),
              appearance: 'fill',
              required: true,
              icon: 'user',
              hideRequiredMarker: true,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'flex-fill left',
            key: 'company_role',
            type: 'custominput',
            templateOptions: {
              label: this.translate.instant('ADMIN.USER.JOB_TITLE'),
              placeholder: this.translate.instant('ADMIN.USER.JOB_TITLE'),
              appearance: 'fill',
              required: true,
              icon: 'suitcase',
              hideRequiredMarker: true,
            },
          },
          {
            className: 'flex-fill right',
            key: 'linkedin_url',
            type: 'custominput',
            templateOptions: {
              label: this.translate.instant('ADMIN.USER.LINKEDIN_URL'),
              placeholder: this.translate.instant('ADMIN.USER.LINKEDIN_URL'),
              appearance: 'fill',
              icon: 'web',
            },
          },
        ],
      },
    ];
  }

  getRefereeFields() {
    let fields = this.getFields();
    let extraFields: Array<FormlyFieldConfig> = [
      {
        key: 'auto_approve_prospects',
        type: 'radio',
        className: 'two-col-radio',
        defaultValue: true,
        templateOptions: {
          appearance: 'row',
          label: this.translate.instant('COMMON.TEXT.REQUESTS_TO_SHARE'),
          options: [
            {
              value: true,
              label: this.translate.instant(
                'COMMON.TEXT.AUTO_APPROVE_PROSPECTS'
              ),
            },
            {
              value: false,
              label: this.translate.instant(
                'COMMON.TEXT.MANUAL_APPROVE_PROSPECTS'
              ),
            },
          ],
        },
      },
      // {
      //   type: 'formly-template',
      //   template: `
      //       <hr>
      //       <div class="sub-header">${this.translate.instant(
      //         'PROFILE.BLOCKED_COMPANY'
      //       )}</div>
      //     `,
      // },
    ];
    return [...fields, ...extraFields];
  }

  addBlockedOrganizations(item) {
    if (!this.blockedOrganizations) {
      this.blockedOrganizations = [];
    }
    this.blockedOrganizations.push(item);
    this.profile.blocked_organizations = [...this.blockedOrganizations];
    this.handleUpdate(true);
  }

  removeBlockedOrganization(index) {
    this.blockedOrganizations.splice(index, 1);
    this.profile.blocked_organizations = [...this.blockedOrganizations];
    this.handleUpdate(true);
  }

  handleUpdate(keepModal = false) {
    this.profile['isUserProfileModal'] = true;
    this.store.dispatch(
      AuthActions.updateProfile({ data: this.profile, keepModal: keepModal })
    );
  }

  handleClose() {
    this.store.dispatch(AuthActions.hideProfileModal());
  }
}
