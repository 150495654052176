import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'referenceType',
})
export class ReferenceTypePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: string): string {
    if (value) {
      return this.translate.instant(`DASHBOARD.ROLES_SHORT.${value}`);
    }
    return value;
  }
}
