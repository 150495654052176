import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DashboardService } from '@services/dashboard.service';

import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

// import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { selectAuth } from '@store/selectors';
import { Store, select } from '@ngrx/store';
import { Constants } from '@modules/shared/constants';

@Component({
  selector: 'mgi-support-modal',
  templateUrl: './support-modal.component.html',
  styleUrls: ['./support-modal.component.scss'],
})
export class SupportModalComponent implements OnInit {
  @Input() visible: boolean = false;
  @Output() onCancel = new EventEmitter<any>();
  isFetching: boolean = false;
  // fields: FormlyFieldConfig[];
  form: UntypedFormGroup;
  auth$: Subscription;
  profile: any;
  loaderClass: string = '';
  modalWidth: number = Constants.DEFAULT_MODAL_WIDTH;

  constructor(
    private dashboardService: DashboardService,
    public translate: TranslateService,
    private fb: UntypedFormBuilder,
    private store: Store
  ) {
    this.auth$ = this.store.pipe(select(selectAuth)).subscribe((auth) => {
      this.profile = auth.profile.data;
    });

    this.form = fb.group({
      firstName: [this.profile.first_name, [Validators.required]],
      lastName: [this.profile.last_name, [Validators.required]],
      email: [
        this.profile.email_address,
        {
          validators: [Validators.email, Validators.required],
          updateOn: 'blur',
        },
      ],
      message: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {}

  onSendClick() {
    for (const key in this.form.controls) {
      if (this.form.controls.hasOwnProperty(key)) {
        this.form.controls[key].markAsDirty();
        this.form.controls[key].updateValueAndValidity();
      }
    }
    if (this.form.status !== 'VALID' || this.isFetching) {
      return;
    }
    let data = {
      email_address: this.form.value.email,
      first_name: this.form.value.firstName,
      last_name: this.form.value.lastName,
      text: this.form.value.message,
    };
    this.isFetching = true;
    this.dashboardService.contactSupport(data).subscribe(
      (res) => {
        console.log(res);
        this.loaderClass = 'success';
        setTimeout(() => {
          this.closeModal();
        }, 1000);
      },
      (res) => {
        console.log(res);
        //todo error
        this.loaderClass = 'failure';
        setTimeout(() => {
          this.closeModal();
        }, 1000);
      }
    );
  }

  reset() {
    this.form.reset();
    this.form.controls.firstName.setValue(this.profile.first_name);
    this.form.controls.lastName.setValue(this.profile.last_name);
    this.form.controls.email.setValue(this.profile.email_address);
    this.isFetching = false;
  }

  closeModal() {
    this.reset();
    this.onCancel.emit();
    this.loaderClass = '';
  }
}
