<div
  class="header"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  [ngClass]="[selectedRole ? selectedRole : '']"
>
  <svg-icon
    class="mgi-verified-logo"
    src="/assets/images/mgi-verified-logo.svg"
  ></svg-icon>
  <div
    *ngIf="isOrgSelectorVisible"
    [ngClass]="[
      'org-selector-container',
      profile.organizations.length === 1 ? 'disabled' : ''
    ]"
  >
    <div class="org-selector-button">
      <span *ngIf="!selectedOrg"> Select an organization </span>
      <img
        *ngIf="!!selectedOrg"
        [src]="selectedOrg.logoUrl"
        class="org-selector-button-img"
      />
      <svg-icon
        *ngIf="profile.organizations.length > 1"
        class="down-icon"
        src="/assets/images/icons/down.svg"
      ></svg-icon>
    </div>
    <div class="org-selector-dropdown">
      <p>{{ profile.organizations.length }} Companies available</p>
      <ul>
        <li>
          <div class="org-selector-item">
            <div class="org-selector-item-inner">
              <img src="/assets/images/verified.svg" />
              <div class="org-selector-item-inner-text">
                <span> View all Companies </span>
                <strong> {{ totalRefCount }} videos </strong>
              </div>
            </div>
            <mgi-button
              type="primary"
              *ngIf="!selectedOrg || selectedOrg.id !== 'all'"
              (click)="onOrgSelect({ id: 'all' })"
            >
              Select
            </mgi-button>
            <span *ngIf="selectedOrg?.id === 'all'"> Current </span>
          </div>
        </li>
        <li *ngFor="let org of profile.organizations">
          <div class="org-selector-item">
            <div class="org-selector-item-inner">
              <img [src]="org.logoUrl" />
              <div class="org-selector-item-inner-text">
                <span>
                  {{ org.name }}
                </span>
                <strong> {{ org.referenceCount }} videos </strong>
              </div>
            </div>
            <mgi-button
              type="primary"
              (click)="onOrgSelect(org)"
              *ngIf="!selectedOrg || selectedOrg.id !== org.id"
            >
              Select
            </mgi-button>
            <span *ngIf="selectedOrg && selectedOrg.id === org.id">
              Current
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div fxLayout class="right-section">
    <div fxLayout="column">
      <div class="name">
        {{ this.profile.first_name }} {{ this.profile.last_name }}
      </div>
      <div class="type">
        <span> {{ selectedRole | referenceType }} </span>
      </div>
    </div>
    <div>
      <div
        nz-dropdown
        nzTrigger="click"
        [nzVisible]="isShowProfile"
        (nzVisibleChange)="profileVisibleChange()"
        [nzDropdownMenu]="menu"
        class="profile-dropdown-section"
        nzOverlayClassName="profile-dropdown"
        [ngClass]="[isShowProfile ? 'showing' : '']"
      >
        <mgi-button
          className="ant-btn-red-purple circle profile-btn"
          size="small"
          (click)="onProfileBtnClick()"
        >
          {{ displayText }}
        </mgi-button>
        <svg-icon
          class="down-w-icon"
          src="/assets/images/icons/down-w.svg"
        ></svg-icon>
      </div>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <div
          class="organizaiton-section"
          *ngIf="!!selectedOrg && !isAdmin && profile.organizations.length > 1"
        >
          <div class="org-selector-item no-spacing" *ngIf="!isOrgListVisible">
            <div class="org-selector-item-inner">
              <img [src]="selectedOrg.logoUrl" />
              <div class="org-selector-item-inner-text">
                <span> Selected Company </span>
                <strong>
                  {{ selectedOrg.name }}
                </strong>
              </div>
            </div>
            <mgi-button type="primary" (click)="onToggleOrgList()">
              Change
            </mgi-button>
          </div>
          <div class="org-selector-item" *ngIf="isOrgListVisible">
            <ul>
              <li>
                <div class="org-selector-item">
                  <div class="org-selector-item-inner">
                    <div class="org-selector-item-img-container">
                      <img src="/assets/images/verified.svg" />
                    </div>
                    <div class="org-selector-item-inner-text">
                      <span> View all Companies </span>
                      <strong> {{ totalRefCount }} videos </strong>
                    </div>
                  </div>
                  <mgi-button
                    type="primary"
                    *ngIf="!selectedOrg || selectedOrg.id !== 'all'"
                    (click)="onOrgSelect({ id: 'all' })"
                  >
                    Select
                  </mgi-button>
                  <span *ngIf="selectedOrg.id === 'all'"> Current </span>
                </div>
              </li>
              <li *ngFor="let org of profile.organizations">
                <div class="org-selector-item">
                  <div class="org-selector-item-inner">
                    <div class="org-selector-item-img-container">
                      <img [src]="org.logoUrl" />
                    </div>
                    <div class="org-selector-item-inner-text">
                      <span>
                        {{ org.name }}
                      </span>
                      <strong> {{ org.referenceCount }} videos </strong>
                    </div>
                  </div>
                  <mgi-button
                    type="primary"
                    (click)="onOrgSelect(org)"
                    *ngIf="!selectedOrg || selectedOrg.id !== org.id"
                  >
                    Select
                  </mgi-button>
                  <span *ngIf="selectedOrg && selectedOrg.id === org.id">
                    Current
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="notifications-section" *ngIf="isReferee && !isAdmin">
          <div class="sub-title">
            {{ numberOfPending }} {{ 'COMMON.TEXT.NOTIFICATIONS' | translate }}
          </div>
          <div class="notification-rows">
            <div
              class="row"
              fxLayout="row"
              *ngFor="let prospect of pendingProspects"
            >
              <div
                class="thumbnail"
                fxLayout="row"
                fxLayoutAlign="center center"
              >
                <svg-icon
                  class="share-icon"
                  src="/assets/images/icons/share.svg"
                ></svg-icon>
              </div>
              <div class="text" fxLayout="column">
                <div class="top">
                  {{ 'COMMON.TEXT.SHARE_REQUEST_APPROVAL' | translate }}
                </div>
                <div class="bottom" fxLayout="row">
                  {{ prospect.prospect_first_name }}
                  {{ prospect.prospect_last_name }}
                  {{ 'COMMON.TEXT.WANT_SHARE' | translate }} "{{
                    prospect.reference_title
                  }}"
                </div>
              </div>
              <mgi-button
                size="medium"
                className="confirm-btn"
                (click)="handleApprove(prospect)"
              >
                <svg-icon
                  class="checkmark-icon"
                  src="/assets/images/icons/checkmark.svg"
                ></svg-icon>
                {{ 'COMMON.TEXT.YES' | translate }}
              </mgi-button>

              <mgi-button
                size="medium"
                className="cancel-btn"
                (click)="handleReject(prospect)"
              >
                <svg-icon
                  class="close-icon"
                  src="/assets/images/icons/close.svg"
                ></svg-icon>
                {{ 'COMMON.TEXT.NO' | translate }}
              </mgi-button>
            </div>
          </div>
        </div>
        <div
          class="buttons-row"
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutAlign.xs="center center"
        >
          <mgi-button
            nz-button
            size="medium"
            type="default"
            className="ant-btn-trans-border"
            (click)="isInviteModalOpen = true"
            *ngIf="isSeller"
          >
            <svg-icon
              class="settings-icon"
              src="/assets/images/icons/user-add-large.svg"
            ></svg-icon>
            <span class="text"> Invite user </span>
          </mgi-button>
          <mgi-button
            className="ref-type-btn ant-btn-trans-border"
            type="default"
            nzOverlayClassName="references-type-dropdown"
            nz-dropdown
            [nzDropdownMenu]="referencesTypeMenu"
          >
            <span class="name-icon"> {{ displayText }} </span>
            <span class="text"> {{ selectedRole | referenceType }} </span>
            <svg-icon
              class="down-icon"
              src="/assets/images/icons/down.svg"
            ></svg-icon>
          </mgi-button>
          <nz-dropdown-menu #referencesTypeMenu="nzDropdownMenu">
            <ul nz-menu>
              <li
                nz-menu-item
                *ngIf="isReferee"
                [ngClass]="[selectedRole === 'referee' ? 'active' : '']"
                (click)="openDashboardWithType('referee')"
              >
                {{ 'DASHBOARD.ROLES.referee' | translate }}
              </li>
              <li
                nz-menu-item
                *ngIf="isProspect"
                [ngClass]="[selectedRole === 'prospect' ? 'active' : '']"
                (click)="openDashboardWithType('prospect')"
              >
                {{ 'DASHBOARD.ROLES.prospect' | translate }}
              </li>
              <li
                nz-menu-item
                *ngIf="isSeller"
                [ngClass]="[selectedRole === 'seller' ? 'active' : '']"
                (click)="openDashboardWithType('seller')"
              >
                {{ 'DASHBOARD.ROLES.seller' | translate }}
              </li>
            </ul>
          </nz-dropdown-menu>

          <mgi-button
            nz-button
            size="medium"
            type="default"
            className="ant-btn-trans-border"
            (click)="openPreferences()"
            *ngIf="!isAdmin"
          >
            <svg-icon
              class="settings-icon"
              src="/assets/images/icons/settings.svg"
            ></svg-icon>
            <span class="text" *ngIf="!isSeller"> Settings </span>
            <span class="text" class="settings-text"> Settings </span>
          </mgi-button>
          <mgi-button
            nz-button
            size="medium"
            type="default"
            className="ant-btn-trans-border"
            (click)="doLogout()"
          >
            <svg-icon
              class="turn-off-icon"
              src="/assets/images/icons/turn-off.svg"
            ></svg-icon>
            <span class="text">
              {{ 'COMMON.TEXT.LOGOUT' | translate }}
            </span>
          </mgi-button>
        </div>
      </nz-dropdown-menu>
    </div>
  </div>
</div>
<mgi-invite-user-modal
  (cancel)="isInviteModalOpen = false"
  [visible]="isInviteModalOpen"
  (createUser)="onCreateSellerUser($event)"
></mgi-invite-user-modal>

<nz-modal
  [(nzVisible)]="!!invitationLinks.length || isFetching"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  nzClassName="share-modal"
  (nzOnCancel)="closeModal()"
  nzMaskClosable="false"
  [nzWidth]="modalWidth"
>
  <ng-template #modalContent>
    <div *ngIf="isFetching" class="ui-loader-holder">
      <div class="util-loader" [class]="loaderClass">
        <div class="util-loader-checkmark">
          <i nz-icon [nzType]="'check-circle'" nzTheme="outline"></i>
        </div>
        <div class="util-loader-error">
          <i nz-icon [nzType]="'close-circle'" nzTheme="outline"></i>
        </div>
      </div>
    </div>
    <div
      className="invitation-links-content"
      *ngIf="invitationLinks.length > 0"
    >
      <div class="modal-header" fxLayout="column" fxLayoutAlign="center center">
        <mgi-text type="h3">Thank you! </mgi-text>
        <mgi-text type="h5">
          An invite has been sent. In addition, you can copy the following link
          to send to the recipient yourself.</mgi-text
        >
      </div>
      <table>
        <thead>
          <th>Email</th>
          <th>Link</th>
        </thead>
        <tbody>
          <tr class="invitation-links" *ngFor="let link of invitationLinks">
            <td>
              {{ link.email }}
            </td>
            <td>
              <div class="invitation-link-wrapper">
                <span class="invitation-link-wrapper-text">
                  {{ link.link }}
                </span>
                <span (click)="onCopy(link.link)">
                  <svg-icon
                    class="svg-icon"
                    src="/assets/images/copy.svg"
                  ></svg-icon>
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-template>

  <ng-template #modalFooter>
    <div fxLayout="column" fxLayoutAlign="center center">
      <mgi-button
        *ngIf="invitationLinks.length > 0"
        size="large"
        (click)="closeModal()"
      >
        Dismiss
      </mgi-button>
    </div>
  </ng-template>
</nz-modal>
