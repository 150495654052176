import { createAction, props } from '@ngrx/store';

export enum ProspectsActionTypes {
  GET_PENDING_PROSPECTS = 'GET_PENDING_PROSPECTS',
  GET_PENDING_PROSPECTS_SUCCESS = 'GET_PENDING_PROSPECTS_SUCCESS',
  GET_PENDING_PROSPECTS_ERROR = 'GET_PENDING_PROSPECTS_ERROR',
  TOGGLE_PENDING_PROSPECTS_MODAL = 'TOGGLE_PENDING_PROSPECTS_MODAL',
}

export const getPendingProspects = createAction(
  ProspectsActionTypes.GET_PENDING_PROSPECTS,
  props<{
    current_page?: number;
    page_size?: number;
  }>()
);
export const getPendingProspectsSuccess = createAction(
  ProspectsActionTypes.GET_PENDING_PROSPECTS_SUCCESS,
  props<{ payload: any }>()
);
export const getPendingProspectsError = createAction(
  ProspectsActionTypes.GET_PENDING_PROSPECTS_ERROR,
  props<{ error: Error }>()
);
export const togglePendingProspectsModal = createAction(
  ProspectsActionTypes.TOGGLE_PENDING_PROSPECTS_MODAL,
  props<{ payload: any }>()
);

export const ProspectsActions = {
  getPendingProspects,
  getPendingProspectsSuccess,
  getPendingProspectsError,
  togglePendingProspectsModal,
};
