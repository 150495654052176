import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import {
  HttpClient,
  HTTP_INTERCEPTORS,
  HttpClientModule,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
// import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';

import { AppEffects } from '@effects/app.effects';
import { AuthEffects } from '@effects/auth.effects';
import { OrganizationEffects } from '@effects/organization.effects';
import { environment } from '@environments/environment';
import { AuthGuard } from '@guards/auth.guard';
import { AdminGuard } from '@guards/admin.guard';
import { ApiPrefixInterceptor } from '@interceptors/api-prefix-interceptor';
import { CustomFormlyModule } from '@modules/custom-formly/custom-formly.module';
import { SharedModule } from '@modules/shared/shared.module';
import { reducers } from '@reducers';
import { AppConfigService } from '@services/app-config.service';
import { metaReducers } from '@store/metaReducers';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LoginProxyGuard } from '@guards/login-proxy.guard';

registerLocaleData(en);

const initializerConfigFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    EffectsModule.forRoot([AppEffects, AuthEffects, OrganizationEffects]),
    CustomFormlyModule,
    FormlyModule.forRoot(),
    FormsModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    SharedModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreModule.forRoot(reducers, { metaReducers }),
    // Connects RouterModule with StoreModule, uses MinimalRouterStateSerializer by default
    // StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializerConfigFn,
      multi: true,
      deps: [AppConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    AuthGuard,
    AdminGuard,
    LoginProxyGuard,
    { provide: NZ_I18N, useValue: en_US },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
