import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mgi-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormComponent implements OnInit {
  @Input() form: UntypedFormGroup | UntypedFormArray = new UntypedFormGroup({});
  @Input() layout: 'horizontal' | 'vertical' | 'inline' = 'vertical';
  @Input() model: any = {};
  @Input() schema: FormlyFieldConfig[];
  @Input() submitText: string = this.translate.instant('COMMON.TEXT.SUBMIT');
  @Input() enableFooter = true;

  @Output() change = new EventEmitter<any>();
  @Output() submit = new EventEmitter<any>();

  @ContentChild('content') content: ElementRef;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private translate: TranslateService
  ) {
    this.renderer.addClass(this.elementRef.nativeElement, 'mgi-form');
  }

  ngOnInit(): void {}

  handleSubmit() {
    this.submit.emit(this.model);
  }

  handleChangeEvent() {
    this.change.emit(this.model);
  }
}
