// import * as fromRouter from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '@reducers/app.reducer';
import { AuthState } from '@reducers/auth.reducer';
import { ReferenceState } from '@modules/dashboard/reducers/reference.reducer';
import { ReferencesState } from '@modules/dashboard/reducers/references.reducer';
import { StatsState } from '@modules/dashboard/reducers/stats.reducer';
import { ProspectsState } from '@modules/dashboard/reducers/prospects.reducer';
import { AdminReferencesState } from '@modules/admin-dashboard/reducers/admin-references.reducer';
import { AdminReferenceState } from '@modules/admin-dashboard/reducers/admin-reference.reducer';
import { AdminUsersState } from '@modules/admin-dashboard/reducers/admin-users.reducer';
import { AdminOrganizationsState } from '@modules/admin-dashboard/reducers/admin-organizations.reducer';
import { AllUsersState } from '@modules/admin-dashboard/reducers/all-users.reducer';
import { OrganizationState } from '@reducers/organization.reducer';
import { TagsState } from '@modules/dashboard/reducers/tags.reducer';
import { AdminEmailsState } from '@modules/admin-dashboard/reducers/admin-emails.reducer';
import { AdminSmsState } from '@modules/admin-dashboard/reducers/admin-sms.reducer';
import { AdminActivityLogState } from '@modules/admin-dashboard/reducers/admin-activity-log.reducer';

export interface ClientState {
  reference: ReferenceState;
  references: ReferencesState;
  prospects: ProspectsState;
  adminReferences: AdminReferencesState;
  adminReference: AdminReferenceState;
  adminUsers: AdminUsersState;
  adminOrganizations: AdminOrganizationsState;
  allUsers: AllUsersState;
  app: AppState;
  auth: AuthState;
  stats: StatsState;
  tags: TagsState;
  emails: AdminEmailsState;
  sms: AdminSmsState;
  // router: fromRouter.RouterReducerState<any>;
}

export const selectApp = createFeatureSelector<AppState>('app');
export const selectAuth = createFeatureSelector<AuthState>('auth');
// export const selectRouter = createFeatureSelector<
//   ClientState,
//   fromRouter.RouterReducerState<any>
// >('router');

export const selectReference = createFeatureSelector<ReferenceState>(
  'reference'
);

export const selectReferences = createFeatureSelector<ReferencesState>(
  'references'
);

export const selectStats = createFeatureSelector<StatsState>('stats');

export const selectTags = createFeatureSelector<TagsState>('tags');
export const selectEmails = createFeatureSelector<AdminEmailsState>('emails');

export const selectSms = createFeatureSelector<AdminEmailsState>('sms');

export const selectProspects = createFeatureSelector<ProspectsState>(
  'prospects'
);

export const selectAdminReference = createFeatureSelector<AdminReferenceState>(
  'adminReference'
);

export const selectAdminReferences = createFeatureSelector<
  AdminReferencesState
>('adminReferences');

export const selectAdminUsers = createFeatureSelector<AdminUsersState>(
  'adminUsers'
);

export const selectAdminOrganizations = createFeatureSelector<
  AdminOrganizationsState
>('adminOrganizations');

export const selectAllUsers = createFeatureSelector<AllUsersState>('allUsers');

export const selectOrganization = createFeatureSelector<OrganizationState>(
  'organization'
);

export const selectActivityLog = createFeatureSelector<AdminActivityLogState>(
  'activityLog'
);

// export const {
//   selectCurrentRoute, // select the current route
//   selectQueryParams, // select the current route query params
//   selectQueryParam, // factory function to select a query param
//   selectRouteParams, // select the current route params
//   selectRouteParam, // factory function to select a route param
//   selectRouteData, // select the current route data
//   selectUrl, // select the current url
// } = fromRouter.getSelectors(selectRouter);

export const selectIsAuthenticated = createSelector(
  selectAuth,
  (auth: AuthState) => auth.authenticated
);

export const selectAuthToken = createSelector(
  selectAuth,
  (auth: AuthState) => auth.token
);

export const selectAuthProfile = createSelector(
  selectAuth,
  (auth: AuthState) => auth.profile
);

export const selectAppIsReady = createSelector(
  selectApp,
  (app: AppState) => app.isReady
);

export const selectReferenceState = createSelector(
  selectReference,
  (reference) => reference
);

export const selectReferencesState = createSelector(
  selectReferences,
  (references) => references
);

export const selectStatsState = createSelector(selectStats, (stats) => stats);

export const selectTagsState = createSelector(selectTags, (stats) => stats);

export const selectAdminEmailsState = createSelector(
  selectEmails,
  (emails) => emails
);

export const selectAdminSmsState = createSelector(
  selectSms,
  (emails) => emails
);

export const selectProspectsState = createSelector(
  selectProspects,
  (prospects) => prospects
);

export const selectAdminReferenceState = createSelector(
  selectAdminReference,
  (adminReference) => adminReference
);

export const selectAdminReferencesState = createSelector(
  selectAdminReferences,
  (adminReferences) => adminReferences
);

export const selectAdminReferencesVideoModalState = createSelector(
  selectAdminReferences,
  (adminReferences) => adminReferences.referenceVideoModal
);

export const selectAdminUsersState = createSelector(
  selectAdminUsers,
  (adminUsers) => adminUsers
);

export const selectAdminOrganizationsState = createSelector(
  selectAdminOrganizations,
  (adminOrganizations) => adminOrganizations
);

export const selectAllUsersState = createSelector(
  selectAllUsers,
  (allUsers) => allUsers
);

export const selectOrganizationState = createSelector(
  selectOrganization,
  (organization) => organization
);
