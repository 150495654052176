import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { OrganizationActions } from '../actions/organization.actions';

export interface OrganizationState {
  data: any;
  isFetching: boolean;
  lastUpdated?: string;
  organization_id: string;
  error?: Error;
}

export const initialState: OrganizationState = {
  data: {},
  organization_id: '',
  isFetching: false,
};

const getOrganization = produce((draft, { organization_id }) => {
  draft.isFetching = true;
  draft.organization_id = organization_id;
  draft.data = {};
});

const getOrganizationSuccess = produce((draft, { payload }) => {
  draft.data = payload;
  draft.isFetching = false;
  draft.lastUpdated = new Date().toISOString();
});

const getOrganizationError = produce((draft, { error }) => {
  draft.error = error;
  draft.isFetching = false;
});

const _organizationReducer = createReducer(
  initialState,
  on(OrganizationActions.getOrganization, getOrganization),
  on(OrganizationActions.getOrganizationSuccess, getOrganizationSuccess),
  on(OrganizationActions.getOrganizationError, getOrganizationError)
);

export function OrganizationReducer(state, action) {
  return _organizationReducer(state, action);
}

/********** Selectors **********/
export const getOrganizationState = createFeatureSelector<OrganizationState>(
  'organization'
);
