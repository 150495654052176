import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mgi-referee',
  templateUrl: './referee.component.html',
  styleUrls: ['./referee.component.scss'],
})
export class RefereeComponent implements OnInit {
  @Input() reference: any;
  @Input() hideVerified: boolean = false;
  constructor() {}

  ngOnInit(): void {}
}
