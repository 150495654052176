import { Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import * as queryString from 'query-string';
import { Subscription, of, EMPTY } from 'rxjs';
import { concatMap, map, withLatestFrom } from 'rxjs/operators';
import { AppActions } from '@actions/app.actions';
import { AppConfigService } from '@services/app-config.service';
import { AuthService } from '@services/auth.service';
import { selectAuth, selectAuthToken } from '@store/selectors';
import { AuthActions } from '@actions/auth.actions';

@AutoUnsubscribe()
@Injectable()
export class AppEffects implements OnDestroy {
  auth$: Subscription;
  token: string;
  userId: string;

  constructor(
    private actions$: Actions,
    private appConfigService: AppConfigService,
    private authService: AuthService,
    private store: Store<any>
  ) {
    this.auth$ = this.store.pipe(select(selectAuth)).subscribe((auth) => {
      this.token = auth.token;
      this.userId = auth.userId;
    });
  }

  startup$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AppActions.startup),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(selectAuthToken)))
        )
      ),
      map(([action, authToken]) => {
        if (authToken) {
          return AuthActions.getProfile({ token: authToken });
        }

        return AppActions.isReady();
      })
    )
  );

  ngOnDestroy() {}
}
