import { forEach as _forEach } from 'lodash';
import stringTemplate from 'string-template';

export default class Utils {
  static normailizeReferenceData(ref: any) {
    let status = 'new',
      statusClass = 'new';
    if (ref.viewed_by_user) {
      (status = 'viewed'), (statusClass = 'viewed');
    }
    if (ref.access_end && new Date(ref.access_end) <= new Date()) {
      status = 'expired';
      statusClass = 'expired';
    }
    return { ...ref, statusClass, status };
  }

  static getTimeRemaining(end: string) {
    let endTime = new Date(end).getTime();
    let now = new Date().getTime();
    let result = {
      days: 0,
      // hours: 0,
      // minutes: 0
    };
    if (endTime < now) {
      return result;
    }
    let diff = (endTime - now) / 1000 / 60;
    let days = diff / 60 / 24;
    //round up
    result.days = Math.ceil(days);
    // result.days = Math.floor(days);
    // let hours = diff / 60 - 24 * result.days;
    // result.hours = Math.floor(hours);
    // let minutes = diff - 24 * 60 * result.days - 60 * result.hours;
    // result.minutes = Math.floor(minutes);
    return result;
  }

  static ObjectsDiff(newObj, oldObj) {
    let diff = {};
    for (let key in newObj) {
      if (newObj.hasOwnProperty(key)) {
        if (oldObj.hasOwnProperty(key)) {
          if (newObj[key] !== oldObj[key]) {
            diff[key] = newObj[key];
          }
        } else {
          diff[key] = newObj[key];
        }
      }
    }
    return diff;
  }

  static convertToFormlySelectOptions(
    options: any,
    labelTemplate,
    valueTemplate
  ) {
    const formlySelectOptions = [];

    if (options) {
      options.forEach((obj: any) => {
        formlySelectOptions.push({
          label: stringTemplate(labelTemplate, obj),
          value: stringTemplate(valueTemplate, obj),
        });
      });
    }

    return formlySelectOptions;
  }

  static getURLStringParams(url: string) {
    var params = {};
    var parser = document.createElement('a');
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  }

  static isEmailString(value: string) {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      value
    );
  }

  static isDomain(value: string) {
    return /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(
      value
    );
  }
}
