<ng-template #textChildren>
  <ng-content></ng-content>
</ng-template>

<h1 *ngIf="type === 'hero-title'" class="hero-title" [style]="styleObject">
  <ng-container *ngTemplateOutlet="textChildren"></ng-container>
</h1>

<h1 *ngIf="type === 'h1'" class="h1" [style]="styleObject">
  <ng-container *ngTemplateOutlet="textChildren"></ng-container>
</h1>

<h2 *ngIf="type === 'h2'" class="h2" [style]="styleObject">
  <ng-container *ngTemplateOutlet="textChildren"></ng-container>
</h2>

<h3 *ngIf="type === 'h3'" class="h3" [style]="styleObject">
  <ng-container *ngTemplateOutlet="textChildren"></ng-container>
</h3>

<h4 *ngIf="type === 'h4'" class="h4" [style]="styleObject">
  <ng-container *ngTemplateOutlet="textChildren"></ng-container>
</h4>

<h5 *ngIf="type === 'h5'" class="h5" [style]="styleObject">
  <ng-container *ngTemplateOutlet="textChildren"></ng-container>
</h5>

<h5 *ngIf="type === 'body-title'" class="body-title" [style]="styleObject">
  <ng-container *ngTemplateOutlet="textChildren"></ng-container>
</h5>

<h5 *ngIf="type === 'body-text'" class="body-text" [style]="styleObject">
  <ng-container *ngTemplateOutlet="textChildren"></ng-container>
</h5>

<p *ngIf="type === 'paragraph'" class="paragraph" [style]="styleObject">
  <ng-container *ngTemplateOutlet="textChildren"></ng-container>
</p>

<p *ngIf="type === 'foot-note'" class="foot-note" [style]="styleObject">
  <ng-container *ngTemplateOutlet="textChildren"></ng-container>
</p>
