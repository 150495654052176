<div class="referee" fxLayout fxLayoutAlign="start center">
  <svg-icon class="user-icon" fxLayout src="/assets/images/icons/user.svg">
  </svg-icon>
  <span class="name">
    {{ reference.referee_first_name }}
    {{ reference.referee_last_name }}
  </span>
  <span fxHide.lt-sm class="verified" *ngIf="!hideVerified">
    <!-- {{ 'COMMON.TEXT.VERIFIED' | translate }} -->
    <svg-icon
      class="verified-logo-label-icon"
      src="/assets/images/icons/verified-logo-label.svg"
    ></svg-icon>
  </span>
</div>
