import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Constants } from '@modules/shared/constants';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'mgi-invite-user-modal',
  templateUrl: './invite-user-modal.component.html',
  styleUrls: ['./invite-user-modal.component.scss'],
})
export class InviteUserModalComponent implements OnInit {
  @Output() cancel = new EventEmitter<any>();
  @Output() createUser = new EventEmitter<any>();
  @Input() visible = false;
  form: UntypedFormGroup;
  modalWidth: number = Constants.DEFAULT_MODAL_WIDTH;

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.createEmptyForm();
  }

  ngOnInit(): void {}

  createEmptyForm() {
    return this.fb.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email_address: [
        '',
        {
          validators: [Validators.email, Validators.required],
          updateOn: 'blur',
        },
      ],
    });
  }

  closeModal() {
    this.form.reset();
    this.cancel.emit();
  }

  onCreateUser() {
    this.createUser.emit(this.form);
  }
}
