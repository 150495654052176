<nz-modal
  [(nzVisible)]="visible"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  nzClassName="share-modal"
  (nzOnCancel)="closeModal()"
  nzMaskClosable="false"
  [nzWidth]="modalWidth"
>
  <ng-template #modalContent>
    <div *ngIf="isFetching" class="ui-loader-holder">
      <div class="util-loader" [class]="loaderClass">
        <div class="util-loader-checkmark">
          <i nz-icon [nzType]="'check-circle'" nzTheme="outline"></i>
        </div>
        <div class="util-loader-error">
          <i nz-icon [nzType]="'close-circle'" nzTheme="outline"></i>
        </div>
      </div>
    </div>
    <div class="text">
      {{ 'SUPPORT.MODAL.TEXT' | translate }}
    </div>
    <form nz-form [formGroup]="form" nzLayout="vertical">
      <div fxLayout="row" class="row">
        <nz-form-item class="flex-fill left">
          <nz-form-label nzNoColon="true">
            {{ 'COMMON.TEXT.FIRST_NAME' | translate }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="'COMMON.TEXT.REQUIRED' | translate">
            <nz-input-group [nzPrefix]="prefixUserIconTemplate">
              <input
                nz-input
                type="text"
                formControlName="firstName"
                [placeholder]="'COMMON.TEXT.FIRST_NAME' | translate"
              />
            </nz-input-group>
            <ng-template #prefixUserIconTemplate>
              <svg-icon
                class="svg-icon"
                src="/assets/images/icons/user.svg"
              ></svg-icon>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="flex-fill right">
          <nz-form-label nzNoColon="true">
            {{ 'COMMON.TEXT.LAST_NAME' | translate }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="'COMMON.TEXT.REQUIRED' | translate">
            <nz-input-group [nzPrefix]="prefixUserIconTemplate">
              <input
                nz-input
                type="text"
                formControlName="lastName"
                [placeholder]="'COMMON.TEXT.LAST_NAME' | translate"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div fxLayout="row">
        <nz-form-item fxFlex>
          <nz-form-control [nzErrorTip]="'COMMON.TEXT.REQUIRED' | translate">
            <nz-input-group>
              <textarea
                type="text"
                nz-input
                [placeholder]="'SUPPORT.MODAL.ENTER_YOUR_QUESTION' | translate"
                formControlName="message"
              >
              </textarea>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div fxLayout="row" class="row-field">
        <nz-form-item fxFlex>
          <nz-form-label nzNoColon="true">
            {{ 'COMMON.TEXT.EMAIL_ADDRESS' | translate }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="emailErrorTpl">
            <nz-input-group [nzPrefix]="prefixTemplateEmail">
              <input
                nz-input
                type="text"
                formControlName="email"
                [placeholder]="'COMMON.TEXT.EMAIL_ADDRESS' | translate"
              />
            </nz-input-group>
            <ng-template #prefixTemplateEmail
              ><i nz-icon nzType="mail" nzTheme="outline"></i
            ></ng-template>
            <ng-template #emailErrorTpl let-control>
              <ng-container *ngIf="control.hasError('email')">
                {{ 'COMMON.TEXT.INCORRECT_EMAIL' | translate }}
              </ng-container>
              <ng-container *ngIf="control.hasError('required')">
                {{ 'COMMON.TEXT.REQUIRED' | translate }}
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <mgi-button
          size="large"
          fxHide.lt-sm
          type="primary"
          (click)="onSendClick()"
        >
          <strong>{{ 'COMMON.TEXT.SUBMIT' | translate }}</strong>
        </mgi-button>
      </div>
    </form>
  </ng-template>
  <ng-template #modalFooter> </ng-template>
</nz-modal>
