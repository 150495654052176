import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { get as _get, has as _has } from 'lodash';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AuthActions } from '@actions/auth.actions';
import { AdminService } from '@services/admin.service';
import { AppConfigService } from '@services/app-config.service';
import { AuthService } from '@services/auth.service';
import { AppActions } from '@actions/app.actions';

export function navigateAfterLogin(router, route, isAdmin = false) {
  if (
    router.url.indexOf('/session') === 0 ||
    router.url.indexOf('/login') === 0 ||
    (route.url &&
      route.url.length &&
      (route.url[0].path === 'invite' ||
        route.url[0].path === 'login' ||
        route.url[0].path === 'resetPassword'))
  ) {
    if (isAdmin) {
      return router.navigate(['admin']);
    }

    // get saved return url from localStorage or query string
    let returnUrl = window.localStorage.getItem('returnUrl');

    const defaultOrgId = getParameterByName('orgId', returnUrl);

    if (defaultOrgId) {
      window.localStorage.setItem('defaultOrgId', defaultOrgId);
    }

    window.localStorage.removeItem('returnUrl');
    returnUrl = returnUrl || route.queryParams.returnUrl;

    let hash = decodeURIComponent(returnUrl);
    let index = hash.indexOf('email_address=');
    if (index != -1) {
      returnUrl = hash.substring(0, index - 1);
    }

    return router.navigate([
      returnUrl && returnUrl.length ? returnUrl : 'dashboard',
    ]);
  }
}

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private adminService: AdminService,
    private appConfigService: AppConfigService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap((action) =>
        this.authService.login(action.email, action.pwd).pipe(
          switchMap((response: any) => [
            // TODO save Authorities to permissions reducer
            AuthActions.loginSuccess({ payload: response }),
            AuthActions.getProfile({ token: response.session_token }),
          ]),
          // tap(() => navigateAfterLogin(this.router, this.route)),
          catchError((error) => of(AuthActions.loginError({ error })))
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      // for no logout api
      // map(() => AuthActions.logoutSuccess()),
      // tap(() => this.router.navigate(['login'])),
      // catchError((error) => of(AuthActions.logoutError({ error })))
      // if there is a logout api
      switchMap((action) =>
        this.authService.logout(action.token).pipe(
          map(() => AuthActions.logoutSuccess()),
          tap(() => this.router.navigate(['login'])),
          catchError((error) =>
            of(AuthActions.logoutError({ error })).pipe(
              tap(() => this.router.navigate(['login']))
            )
          )
        )
      )
    )
  );

  getProfile$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AuthActions.getProfile),
      switchMap((action) =>
        this.authService.getProfile(action.token).pipe(
          mergeMap((payload) => {
            return [
              AuthActions.getProfileSuccess({ payload }),
              AppActions.isReady(),
            ];
          }),
          tap((response) => {
            if (response.type === AuthActions.getProfileSuccess.type) {
              navigateAfterLogin(
                this.router,
                this.route,
                _get(response, 'payload.is_admin')
              );
            }

            return response;
          }),
          switchMap((response) => {
            if (response.type === AuthActions.getProfileSuccess.type) {
              const payload = response.payload;
              if (
                !_has(payload, 'auto_approve_prospects') &&
                payload.reference_roles.includes('referee')
              ) {
                return [
                  response,
                  AuthActions.showProfileModal({ isFirstTime: true }),
                ];
              }
            }

            return [response];
          }),
          catchError((error) =>
            of(
              AuthActions.getProfileError({ error }),
              AppActions.isReady()
            ).pipe(tap(() => this.router.navigate(['login'])))
          )
        )
      )
    )
  );

  updateProfile$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AuthActions.updateProfile),
      switchMap((action) =>
        this.adminService.updateProfile(action.data).pipe(
          switchMap(() =>
            action.keepModal
              ? of(AuthActions.setProfile({ payload: action.data }))
              : of(
                  AuthActions.setProfile({ payload: action.data }),
                  AuthActions.hideProfileModal()
                )
          ),
          catchError((error) => of(AuthActions.updateProfileError({ error })))
        )
      )
    )
  );

  sessionCheck$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AuthActions.sessionCheck),
      switchMap((action) =>
        this.authService.getProfile(action.token).pipe(
          switchMap((response) =>
            of(AuthActions.sessionCheckSuccess({ payload: response }))
          ),
          catchError((error) =>
            of(AuthActions.sessionCheckError({ error })).pipe(
              tap(() => this.router.navigate(['login']))
            )
          )
        )
      )
    )
  );
}

function getParameterByName(paramName: string, url = window.location.href) {
  const name = paramName.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
