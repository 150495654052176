import { Component, Input, Self, Optional, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'mgi-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements ControlValueAccessor {
  @Input() placeholder = '';
  @Input() disabled: boolean;
  @Input() minlength: string;
  @Input() maxlength: string;
  @Input() suffixIcon: TemplateRef<any> | string;

  private _options: Array<{
    label: string;
    value: string;
  }>;

  @Input() set options(
    options: Array<{
      label: string;
      value: string;
    }>
  ) {
    this._options = options;
    this.filteredOptions = this._options;
  }

  get options(): Array<{
    label: string;
    value: string;
  }> {
    return this._options;
  }

  emailVal = '';
  filteredOptions: Array<{
    label: string;
    value: string;
  }> = [];
  mode: 'new' | 'select' = 'select';
  val = '';

  constructor(
    @Self()
    @Optional()
    private ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    this.filteredOptions = this.options;
  }

  nzFilterOption = () => true;

  onChange: any = () => {};

  handleSearch(value: string): void {
    this.filteredOptions = this.options.filter(
      (option) => option.label.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  onTouched: any = () => {};

  get value(): string {
    if (this.mode === 'new') {
      return this.emailVal;
    }

    return this.val;
  }

  set value(val: string) {
    if (val !== undefined && this.val !== val) {
      if (this.mode === 'new') {
        this.emailVal = val;
      } else {
        this.val = val;
      }
      this.onChange(val);
      this.onTouched(val);
    }
  }

  // toggleMode() {
  //   this.mode = this.mode === 'new' ? 'select' : 'new';
  //   this.value = this.mode === 'new' ? '' : this.val;
  // }

  // this method sets the value programmatically
  writeValue(value: string) {
    this.val = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
