import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription, interval } from 'rxjs';
import { AppActions } from '@actions/app.actions';
import { selectApp, selectAuthToken } from '@store/selectors';
import { AuthActions } from '@actions/auth.actions';

@AutoUnsubscribe()
@Component({
  selector: 'mgi-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  app$: Subscription;
  isReady: boolean;
  token$: Subscription;
  sessionToken: string;
  sessionSub: Subscription;

  constructor(private store: Store, public translate: TranslateService) {
    translate.addLangs(['en']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    const sessionCheck = interval(300000); // 5 mins

    this.app$ = store.pipe(select(selectApp)).subscribe((app) => {
      this.isReady = app.isReady;
    });

    this.token$ = store.pipe(select(selectAuthToken)).subscribe((token) => {
      this.sessionToken = token;
    });

    this.sessionSub = sessionCheck.subscribe((n) => {
      if (this.sessionToken) {
        this.store.dispatch(
          AuthActions.sessionCheck({ token: this.sessionToken })
        );
      } else {
        if (this.isReady) {
          this.sessionSub.unsubscribe();
        }
      }
    });
  }

  ngOnInit() {
    if (
      location.hash.indexOf('loginlegals') !== -1 ||
      location.hash.indexOf('policy') !== -1
    ) {
      // this is for route that doesn't need to fetch profile
      this.store.dispatch(AppActions.isReady());
    } else {
      this.store.dispatch(AppActions.startup());
    }
  }

  ngOnDestroy() {}
}
