import { ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { storeLogger } from 'ngrx-store-logger';

import { environment } from '@environments/environment';

function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [
      { auth: ['token', 'userId', 'selectedOrgId', 'selectedRole'] },
      { references: ['type'] },
    ],
    rehydrate: true,
  })(reducer);
}

function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  // default, no options
  return storeLogger({
    collapsed: true,
  })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = environment.production
  ? [localStorageSyncReducer]
  : [localStorageSyncReducer, logger];
