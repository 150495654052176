import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'mgi-formly-wrapper-custom-form-field',
  templateUrl: './formly-wrapper-custom-form-field.component.html',
  styleUrls: ['./formly-wrapper-custom-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyWrapperCustomFormField extends FieldWrapper {
  get errorState() {
    return this.showError ? 'error' : '';
  }
}
