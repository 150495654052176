<div class="header" *ngIf="!hideHeader">
  <b>{{ 'COMMON.TEXT.BLOCKED_ORGANIZATIONS' | translate }}</b>
</div>
<div
  class="adding-row"
  fxLayout="row"
  fxLayout.lt-sm="column"
  fxLayoutAlign.lt-sm="center center"
  *ngIf="mode !== 'readonly'"
>
  <nz-form-item>
    <nz-form-label nzNoColon="true" nzFor="block_company_name">
      {{ 'COMMON.TEXT.COMPANY_NAME_TO_BLOCK' | translate }}
    </nz-form-label>
    <nz-form-control>
      <input
        id="block_company_name"
        nz-input
        type="text"
        [(ngModel)]="newCompanyName"
        [placeholder]="'COMMON.TEXT.COMPANY_NAME' | translate"
      />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzNoColon="true" nzFor="block_company_domain">
      {{ 'COMMON.TEXT.COMPANY_DOMAIN_TO_BLOCK' | translate }}
    </nz-form-label>
    <nz-form-control>
      <input
        id="block_company_domain"
        nz-input
        type="text"
        [(ngModel)]="newCompanyDomain"
        [placeholder]="'COMMON.TEXT.COMPANY_DOMAIN' | translate"
      />
    </nz-form-control>
  </nz-form-item>

  <mgi-button
    size="medium"
    className="modal-btn"
    (click)="addBlockedOrganizations()"
  >
    {{ 'COMMON.TEXT.BLOCK_THIS' | translate }}
  </mgi-button>
</div>
<div class="error-msg" fxLayout="row" *ngIf="errorMsg">
  {{ errorMsg }}
</div>
<div class="list-header" fxLayout fxLayoutAlign="space-between center">
  <span>
    {{ 'COMMON.TEXT.BLOCK_LIST' | translate }}
  </span>
  <span class="line"></span>
</div>
<div class="list">
  <div
    fxLayout="row"
    class="item"
    *ngFor="let org of blockedcOrganizations; let i = index"
  >
    <span class="name">{{ org.name }}</span>
    <span>{{ org.domain }}</span>

    <div *ngIf="mode !== 'readonly'">
      <div class="icon-holder" (click)="handleRemoveItem(i)">
        <svg-icon
          class="svg-icon"
          src="/assets/images/icons/trash.svg"
        ></svg-icon>
      </div>
    </div>
  </div>
  <div *ngIf="blockedcOrganizations.length === 0 && mode === 'readonly'">
    {{ 'COMMON.TEXT.NONE' | translate }}
  </div>
</div>
