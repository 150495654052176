import { Injectable } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

@Injectable({
  providedIn: 'root',
})
export class DeviceSizeService {
  constructor(private mediaObserver: MediaObserver) {}

  isSmallScreen() {
    return this.mediaObserver.isActive('lt-md');
  }

  isMediumScreen() {
    return this.mediaObserver.isActive('md');
  }

  isMediumScreenOrLarger() {
    return this.mediaObserver.isActive('gt-sm');
  }

  isMediumScreenOrSmaller() {
    return this.mediaObserver.isActive('lt-lg');
  }

  isLargeScreen() {
    return this.mediaObserver.isActive('gt-md');
  }
}
