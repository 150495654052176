<div class="video-thumbnail">
  <div class="wrapper" *ngIf="showPlayBtn">
    <svg-icon
      *ngIf="status === 'new' || status === 'viewed'"
      class="play-icon"
      src="/assets/images/icons/play.svg"
    ></svg-icon>
    <svg-icon
      *ngIf="status === 'expired'"
      class="play-icon"
      src="/assets/images/icons/viewed.svg"
    ></svg-icon>
  </div>
  <img *ngIf="status !== 'expired'" [src]="src" />
</div>
