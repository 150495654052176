import { createAction, props } from '@ngrx/store';

export const checkAuth = createAction('APP_CHECK_AUTH');
export const startup = createAction('APP_STARTUP');
export const isReady = createAction('APP_IS_READY');

export const AppActions = {
  checkAuth,
  startup,
  isReady,
};
