import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { AppActions } from '@actions/app.actions';

export interface AppState {
  isReady: boolean;
}

export const initialState: AppState = {
  isReady: false,
};

const isReady = produce((draft) => {
  draft.isReady = true;
});

const appReducer = createReducer(initialState, on(AppActions.isReady, isReady));

export function reducer(state: AppState | undefined, action: Action) {
  return appReducer(state, action);
}
