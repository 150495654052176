import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '@services/app-config.service';
import { AppService } from '@services/app.service';
import { catchError } from 'rxjs/operators';
import { selectAuth, selectReferences } from '@store/selectors';
import { TRoles } from '@reducers/auth.reducer';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  auth$: Subscription;
  references$: Subscription;
  selectedRole?: TRoles;
  token?: string;

  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient,
    private appService: AppService,
    private store: Store
  ) {
    this.auth$ = this.store.pipe(select(selectAuth)).subscribe((auth) => {
      this.token = auth.token;
      this.selectedRole = auth.selectedRole;
    });
  }

  login(email: string, pwd: string) {
    return this.http.post(`${this.appConfigService.getServerUrl()}/session`, {
      email_address: email,
      password: pwd,
    });
  }

  logout(token: string) {
    return this.http.delete(`${this.appConfigService.getServerUrl()}/session`, {
      headers: {
        'x-session-token': token,
      },
    });
  }

  validate(appId: string, token: string) {
    return this.http.post(
      `${this.appConfigService.getLogonLabsAPI()}/validate`,
      {
        appId,
        token,
      },
      {
        headers: {
          'x-app-secret': this.appConfigService.getLogonLabsAppSecret(),
        },
      }
    );
  }

  getProfile(token: string) {
    return this.http
      .get(
        `${this.appConfigService.getServerUrl()}/profile/${
          this.selectedRole ?? 'all'
        }`,
        {
          headers: {
            'x-session-token': token ?? this.token,
          },
        }
      )
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }
}
