<form
  class="mgi-form"
  [formGroup]="form"
  (ngSubmit)="handleSubmit()"
  nzLayout="vertical"
>
  <formly-form [form]="form" [fields]="schema" [model]="model"></formly-form>
  <ng-container *ngIf="enableFooter">
    <div class="ta-form-footer">
      <ng-content select="[footer]"></ng-content>
      <ng-container *ngIf="!content">
        <!-- <mgi-button type="primary" >{{ submitText }}</mgi-button> -->
        <div fxLayout fxLayoutAlign="center center" style="margin-top: 15px">
          <button nz-button nzType="primary">{{ submitText }}</button>
        </div>
      </ng-container>
    </div>
  </ng-container>
</form>
