import { AfterViewInit, Component, Input } from '@angular/core';
import * as shortid from 'shortid';
import './logonlabs.min';

@Component({
  selector: 'mgi-bread-butter',
  templateUrl: './bread-butter.component.html',
  styleUrls: ['./bread-butter.component.scss'],
})
export class BreadButterComponent implements AfterViewInit {
  @Input() appId: string;
  @Input() apiPath: string | undefined;
  @Input() options: { [key: string]: any } | undefined = {};
  @Input() mode: 'form' | 'resetpassword' | 'confirmemail' | 'invitation' =
    'form';
  @Input() theme: 'icon' | undefined;

  public id: string = shortid.generate();

  constructor() {}

  ngAfterViewInit(): void {
    (window as any).logonAsync = () => {
      (window as any).BreadButter.configure({
        app_id: this.appId,
        app_name: 'MGI',
        hide_local_auth_domains: ['domain1.com', 'domain2.com'],
        ...(this.apiPath
          ? {
              api_path: this.apiPath,
            }
          : {}),
      });

      let options = this.options ? this.options : {};
      switch (this.mode) {
        case 'invitation':
        case 'confirmemail':
        case 'resetpassword':
          options.mode = this.mode;
        case 'form':
          (window as any).BreadButter.widgets.signIn(this.id, {
            ...options,
            ...(this.theme ? { theme: this.theme } : {}),
          });
          break;
        default:
      }

      // (window as any).BreadButter.ui.button(this.id, {
      //   ...(this.theme ? { theme: this.theme } : {}),
      // });
    };

    if ((window as any).BreadButter) {
      (window as any).logonAsync();
    }
    // ((d, s, id) => {
    //   let js;
    //   const fjs = d.getElementsByTagName(s)[0];
    //   if (d.getElementById(id)) {
    //     return;
    //   }
    //   js = d.createElement(s);
    //   js.id = id;
    //   js.src = 'https://cdn.logonlabs.com/dist/logonlabs.min.js';
    //   fjs.parentNode.insertBefore(js, fjs);
    // })(document, 'script', 'logon-js');
  }
}
