import { createAction, props } from '@ngrx/store';

export enum OrganizationActionTypes {
  GET_ORGANIZATION = 'GET_ORGANIZATION',
  GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS',
  GET_ORGANIZATION_ERROR = 'GET_ORGANIZATION_ERROR',
}

export const getOrganization = createAction(
  OrganizationActionTypes.GET_ORGANIZATION,
  props<{
    organization_id?: string;
  }>()
);
export const getOrganizationSuccess = createAction(
  OrganizationActionTypes.GET_ORGANIZATION_SUCCESS,
  props<{ payload: any }>()
);
export const getOrganizationError = createAction(
  OrganizationActionTypes.GET_ORGANIZATION_ERROR,
  props<{ error: Error }>()
);

export const OrganizationActions = {
  getOrganization,
  getOrganizationSuccess,
  getOrganizationError,
};
