<ng-template #prefixUserIconTemplate>
  <svg-icon class="svg-icon" src="/assets/images/icons/user.svg"></svg-icon>
</ng-template>
<ng-template #prefixEnvelopeIconTemplate>
  <svg-icon class="svg-icon" src="/assets/images/icons/envelope.svg"></svg-icon>
</ng-template>

<nz-modal
  [(nzVisible)]="visible"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="closeModal()"
  nzMaskClosable="false"
  [nzWidth]="modalWidth"
>
  <ng-template #modalContent>
    <div class="modal-header" fxLayout="column" fxLayoutAlign="center center">
      <mgi-text type="h3">Invite a new user</mgi-text>
    </div>
    <form nz-form [formGroup]="form" nzLayout="vertical">
      <div fxLayout="row" class="row" fxLayout.xs="column">
        <nz-form-item class="flex-fill left">
          <nz-form-label nzNoColon="true">
            {{ 'COMMON.TEXT.FIRST_NAME' | translate }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="'COMMON.TEXT.REQUIRED' | translate">
            <nz-input-group [nzPrefix]="prefixUserIconTemplate">
              <input
                nz-input
                type="text"
                formControlName="first_name"
                [placeholder]="'COMMON.TEXT.FIRST_NAME' | translate"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="flex-fill right">
          <nz-form-label nzNoColon="true">
            {{ 'COMMON.TEXT.LAST_NAME' | translate }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="'COMMON.TEXT.REQUIRED' | translate">
            <nz-input-group [nzPrefix]="prefixUserIconTemplate">
              <input
                nz-input
                type="text"
                formControlName="last_name"
                [placeholder]="'COMMON.TEXT.LAST_NAME' | translate"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
      <nz-form-item class="flex-fill">
        <nz-form-label nzNoColon="true">
          {{ 'COMMON.TEXT.EMAIL_ADDRESS' | translate }}
        </nz-form-label>
        <nz-form-control [nzErrorTip]="'COMMON.TEXT.REQUIRED' | translate">
          <nz-input-group [nzPrefix]="prefixEnvelopeIconTemplate">
            <input
              nz-input
              type="text"
              formControlName="email_address"
              [placeholder]="'COMMON.TEXT.EMAIL_ADDRESS' | translate"
            />
          </nz-input-group>
          <ng-template #emailErrorTpl let-control>
            <ng-container *ngIf="control.hasError('email')">
              {{ 'COMMON.TEXT.INCORRECT_EMAIL' | translate }}
            </ng-container>
            <ng-container *ngIf="control.hasError('required')">
              {{ 'COMMON.TEXT.REQUIRED' | translate }}
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-template>

  <ng-template #modalFooter>
    <div fxLayout="row" fxLayoutAlign="center center">
      <mgi-button size="large" (click)="onCreateUser()">
        Invite user
      </mgi-button>
    </div>
  </ng-template>
</nz-modal>
