<div #tagsDiv class="tags">
  <div class="tag text" *ngFor="let tag of tags | slice: 0:showTagNumber">
    {{ tag.name }}:
    <span *ngFor="let item of tag.values | keyvalue">
      {{ item.value }}
    </span>
  </div>
  <div *ngIf="miniumTagNumber !== tags.length && tags.length > miniumTagNumber">
    <div
      class="tags-toggle"
      *ngIf="showTagNumber < tags.length; else elseBlock"
    >
      <a (click)="showAllTags()">
        + {{ tags.length - this.showTagNumber }}
        {{ 'DASHBOARD.OTHER_TAGS' | translate }}
      </a>
    </div>
    <ng-template #elseBlock>
      <div class="tags-toggle">
        <a (click)="showLessTags()">
          {{ 'DASHBOARD.HIDE_TAGS' | translate }}
        </a>
      </div>
    </ng-template>
  </div>
</div>
