import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { AppConfigService } from '@services/app-config.service';
import { select, Store } from '@ngrx/store';
import { selectAuth } from '@store/selectors';
import { AuthState } from '@reducers/auth.reducer';

/**
 * Prefixes all requests not starting with `http[s]` with the dynamic config.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  private auth$: Subscription;
  private token?: string;

  constructor(
    private readonly appConfig: AppConfigService,
    private store: Store<{ auth: AuthState }>
  ) {
    this.auth$ = this.store.pipe(select(selectAuth)).subscribe((auth) => {
      this.token = auth.token;
    });
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      !/^(http|https):/i.test(request.url) &&
      !request.url.startsWith('/assets/')
    ) {
      request = request.clone({
        url: this.appConfig.getServerUrl() + request.url,
      });
    }

    if (this.token) {
      request = request.clone({
        headers: request.headers.set('x-session-token', this.token.toString()),
      });
    }

    return next.handle(request);
  }
}
