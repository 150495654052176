<ng-template #buttonChildren>
  <ng-content></ng-content>
</ng-template>

<ng-container *ngIf="type === 'link'">
  <a
    [class]="className"
    nz-button
    nzType="link"
    [nzSize]="size"
    [nzShape]="shape"
    [nzBlock]="block"
    [nzLoading]="loading"
    [disabled]="disabled"
    (click)="handleClick($event)"
  >
    <i nz-icon [nzType]="icon" *ngIf="icon"></i>
    <ng-container *ngTemplateOutlet="buttonChildren"></ng-container>
  </a>
</ng-container>

<ng-container *ngIf="type !== 'link'">
  <button
    [class]="className"
    [type]="htmlType"
    nz-button
    [nzType]="type"
    [nzSize]="size"
    [nzShape]="shape"
    [nzBlock]="block"
    [nzLoading]="loading"
    [disabled]="disabled"
    (click)="handleClick($event)"
  >
    <i nz-icon [nzType]="icon" *ngIf="icon"></i>
    <ng-container *ngTemplateOutlet="buttonChildren"></ng-container>
  </button>
</ng-container>
