<div class="autocomplete-field">
  <ng-container *ngIf="mode === 'select'">
    <nz-input-group nzSearch>
      <nz-select
        style="width: 100%;"
        [nzPlaceHolder]="placeholder"
        [(ngModel)]="value"
        [nzShowSearch]="true"
        [nzFilterOption]="nzFilterOption"
        (nzOnSearch)="handleSearch($event)"
      >
        <nz-option
          *ngFor="let o of filteredOptions"
          [nzLabel]="o.label"
          [nzValue]="o.value"
        ></nz-option>
      </nz-select>
    </nz-input-group>
  </ng-container>
  <!-- <ng-container *ngIf="mode === 'new'">
    <nz-input-group nzSearch [nzAddOnAfter]="minusBtn">
      <input type="email" nz-input placeholder="Email" [(ngModel)]="value" />
    </nz-input-group>
  </ng-container> -->
</div>
<!-- <ng-template #addBtn>
  <mgi-button icon="plus" size="medium" (click)="toggleMode()"></mgi-button>
</ng-template>
<ng-template #minusBtn>
  <mgi-button icon="minus" size="medium" (click)="toggleMode()"></mgi-button>
</ng-template> -->
