import { createAction, props } from '@ngrx/store';

export enum StatsActionTypes {
  GET_SELLER_STATS = 'GET_SELLER_STATS',
  GET_SELLER_STATS_SUCCESS = 'GET_SELLER_STATS_SUCCESS',
  GET_SELLER_STATS_ERROR = 'GET_SELLER_STATS_ERROR',

  GET_COMPANY_STATS = 'GET_COMPANY_STATS',
  GEY_COMPANY_STATS_SUCCESS = 'GET_COMPANY_STATS_SUCCESS',
  GEY_COMPANY_STATS_ERROR = 'GET_COMPANY_STATS_ERROR',

  GET_PROSPECT_STATS = 'GET_PROSPECT_STATS',
  GET_PROSPECT_STATS_SUCCESS = 'GET_PROSPECT_STATS_SUCCESS',
  GET_PROSPECT_STATS_ERROR = 'GET_PROSPECT_STATS_ERROR',
  CLEAR_PROSPECT_STATS = 'CLEAR_PROSPECT_STATS',

  GET_REF_STATS = 'GET_REF_STATS',
  GET_REF_STATS_SUCCESS = 'GET_REF_STATS_SUCCESS',
  GET_REF_STATS_ERROR = 'GET_REF_STATS_ERROR',
  CLEAR_REF_STATS = 'CLEAR_REF_STATS',

  REPORT_REF_WATCHED = 'REPORT_REF_WATCHED',
  REPORT_QUICK_LINK_CLICKED = 'REPORT_QUICK_LINK_CLICKED',

  NOOP = 'NOOP',
}

export const getProspectStats = createAction(
  StatsActionTypes.GET_PROSPECT_STATS,
  props<{
    org_id: string;
    user_id: string;
    view_all: boolean;
  }>()
);

export const getProspectStatsSuccess = createAction(
  StatsActionTypes.GET_PROSPECT_STATS_SUCCESS,
  props<{ payload: any }>()
);

export const getProspectStatsError = createAction(
  StatsActionTypes.GET_PROSPECT_STATS_ERROR,
  props<{ error: Error }>()
);

export const clearProspectStats = createAction(
  StatsActionTypes.CLEAR_PROSPECT_STATS
);

export const getSellerStats = createAction(StatsActionTypes.GET_SELLER_STATS);
export const getSellerStatsSuccess = createAction(
  StatsActionTypes.GET_SELLER_STATS_SUCCESS,
  props<{ payload: any }>()
);
export const getSellerStatsError = createAction(
  StatsActionTypes.GET_SELLER_STATS_ERROR,
  props<{ error: Error }>()
);

export const getCompanyStats = createAction(StatsActionTypes.GET_COMPANY_STATS);

export const getCompanyStatsSuccess = createAction(
  StatsActionTypes.GEY_COMPANY_STATS_SUCCESS,
  props<{ payload: any }>()
);

export const getCompanyStatsError = createAction(
  StatsActionTypes.GEY_COMPANY_STATS_ERROR,
  props<{ error: Error }>()
);

export const getRefStats = createAction(
  StatsActionTypes.GET_REF_STATS,
  props<{ ref_id: any; user_id: any }>()
);

export const getRefStatsSuccess = createAction(
  StatsActionTypes.GET_REF_STATS_SUCCESS,
  props<{ payload: any }>()
);

export const getRefStatsError = createAction(
  StatsActionTypes.GET_REF_STATS_ERROR,
  props<{ error: Error }>()
);

export const reportRefWatched = createAction(
  StatsActionTypes.REPORT_REF_WATCHED,
  props<{ ref_id: string }>()
);

export const reportQuickLinkClicked = createAction(
  StatsActionTypes.REPORT_QUICK_LINK_CLICKED,
  props<{ ref_id: string }>()
);

export const statsNoopAction = createAction(StatsActionTypes.NOOP);

export const clearRefStats = createAction(StatsActionTypes.CLEAR_REF_STATS);

export const StatsActions = {
  getSellerStats,
  getSellerStatsSuccess,
  getSellerStatsError,

  getCompanyStats,
  getCompanyStatsSuccess,
  getCompanyStatsError,

  getProspectStats,
  getProspectStatsSuccess,
  getProspectStatsError,
  clearProspectStats,

  getRefStats,
  getRefStatsSuccess,
  getRefStatsError,
  clearRefStats,

  reportRefWatched,
  reportQuickLinkClicked,

  statsNoopAction,
};
