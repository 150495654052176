import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { TranslateModule } from '@ngx-translate/core';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDividerModule } from 'ng-zorro-antd/divider';

import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { ButtonComponent } from './components/button/button.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormComponent } from './components/form/form.component';
import { BreadButterComponent } from './components/bread-butter/bread-butter.component';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { RefereeComponent } from './components/referee/referee.component';
import { SupportModalComponent } from './components/support-modal/support-modal.component';
import { TimeRemainingComponent } from './components/time-remaining/time-remaining.component';
import { UserProfileModalComponent } from './components/user-profile-modal/user-profile-modal.component';
import { VideoThumbnailComponent } from './components/video-thumbnail/video-thumbnail.component';
import { InviteUserModalComponent } from './components/invite-user-modal/invite-user-modal.component';
import { MinuteSecondsPipe } from './pipes/minute-seconds.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { BlockedOrganizationsComponent } from './components/blocked-organizations/blocked-organizations.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ReferenceTypePipe } from './pipes/reference-type.pipe';
import { TextComponent } from './components/text/text.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { RefTagsComponent } from './components/ref-tags/ref-tags.component';

@NgModule({
  declarations: [
    AutocompleteComponent,
    ButtonComponent,
    FooterComponent,
    FormComponent,
    BreadButterComponent,
    MainLayoutComponent,
    NavbarComponent,
    PageLayoutComponent,
    SafeHtmlPipe,
    SafeUrlPipe,
    VideoThumbnailComponent,
    MinuteSecondsPipe,
    TimeRemainingComponent,
    RefereeComponent,
    SupportModalComponent,
    UserProfileModalComponent,
    BlockedOrganizationsComponent,
    ReferenceTypePipe,
    InviteUserModalComponent,
    TextComponent,
    TabsComponent,
    RefTagsComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    FormlyNgZorroAntdModule,
    FormlyModule,
    HttpClientModule,
    NzAutocompleteModule,
    NzButtonModule,
    NzCardModule,
    NzCheckboxModule,
    NzDropDownModule,
    NzFormModule,
    NzGridModule,
    NzIconModule,
    NzLayoutModule,
    NzMenuModule,
    NzModalModule,
    NzRadioModule,
    NzSelectModule,
    NzTabsModule,
    NzToolTipModule,
    NzTypographyModule,
    ReactiveFormsModule,
    TranslateModule,
    NzTableModule,
    NzDatePickerModule,
    NzInputModule,
    AngularSvgIconModule,
    NzDividerModule,
  ],
  exports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    FormlyNgZorroAntdModule,
    FormlyModule,
    HttpClientModule,
    NzButtonModule,
    NzCardModule,
    NzCheckboxModule,
    NzDropDownModule,
    NzFormModule,
    NzGridModule,
    NzIconModule,
    NzLayoutModule,
    NzMenuModule,
    NzModalModule,
    NzRadioModule,
    NzSelectModule,
    NzTabsModule,
    NzToolTipModule,
    NzTypographyModule,
    ReactiveFormsModule,
    TranslateModule,
    AutocompleteComponent,
    ButtonComponent,
    FormComponent,
    BreadButterComponent,
    MainLayoutComponent,
    NavbarComponent,
    PageLayoutComponent,
    SafeHtmlPipe,
    SafeUrlPipe,
    MinuteSecondsPipe,
    VideoThumbnailComponent,
    TimeRemainingComponent,
    RefereeComponent,
    NzTableModule,
    NzDatePickerModule,
    NzInputModule,
    SupportModalComponent,
    FooterComponent,
    UserProfileModalComponent,
    BlockedOrganizationsComponent,
    AngularSvgIconModule,
    NzDividerModule,
    InviteUserModalComponent,
    TextComponent,
    TabsComponent,
    RefTagsComponent,
  ],
})
export class SharedModule {}
