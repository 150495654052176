import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { has as _has } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { AppConfigService } from '@services/app-config.service';
import { AuthState } from '@reducers/auth.reducer';
import { selectAuth } from '@store/selectors';

import { HttpClient } from '@angular/common/http';
import { AppService } from '@services/app.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';
import { ICreateUserResponse } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  auth$: Subscription;
  token: string;
  isAuthenticated: boolean;
  constructor(
    private appConfigService: AppConfigService,
    private appService: AppService,
    private http: HttpClient,
    private store: Store<{ auth: AuthState }>,
    private router: Router
  ) {
    this.auth$ = this.store.pipe(select(selectAuth)).subscribe((auth) => {
      this.token = auth.token;
    });
  }

  getUsers(page_size = 10, current_page = 1) {
    let url = `${this.appConfigService.getServerUrl()}/users`;
    if (page_size) {
      url += `?page_size=${page_size}`;
    }
    if (current_page) {
      url += `&page=${current_page}`;
    }
    if (!environment.production) {
      url += `&errordetails=true`;
    }

    return this.http
      .get(url, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  transformLegacyData(data) {
    const options = {
      email_address: data.email_address,
      is_referee: !!data.is_referee,
      is_seller: !!data.is_seller,
    };

    if (data.first_name) {
      options['first_name'] = data.first_name;
    }

    if (data.last_name) {
      options['last_name'] = data.last_name;
    }

    if (data.company_role) {
      options['company_role'] = data.company_role;
    }

    if (data.company_name) {
      options['company_name'] = data.company_name;
    }

    if (data.phone_number) {
      options['phone_number'] = data.phone_number;
    }

    if (data.organization_id) {
      options['organization_id'] = data.organization_id;
    }

    if (data.can_see_all_insight) {
      options['can_see_all_insight'] = data.can_see_all_insight;
    }

    return {
      users: [options],
    };
  }

  createUser(data: any): Observable<ICreateUserResponse> {
    let url = `${this.appConfigService.getServerUrl()}/users`;

    let payload = null;
    if (!data.users) {
      payload = this.transformLegacyData(data);
    } else {
      payload = data;
    }

    if (!environment.production) {
      url += `?errordetails=true`;
    }
    return this.http
      .post<ICreateUserResponse>(url, payload, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  getProfile() {
    const url = `${this.appConfigService.getServerUrl()}/profile`;

    return this.http
      .get(url)
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  updateProfile(data) {
    let url = '/profile';
    let options = {
      first_name: data.first_name,
      last_name: data.last_name,
    };
    if (data.email_address && !data.isUserProfileModal) {
      options['email_address'] = data.email_address;
    }
    if (data.linkedin_url) {
      options['linkedin_url'] = data.linkedin_url;
    }
    if (typeof data.is_referee !== undefined) {
      options['is_referee'] = data.is_referee;
    }
    if (typeof data.is_seller !== undefined) {
      options['is_seller'] = data.is_seller;
    }
    if (data.company_role) {
      options['company_role'] = data.company_role;
    }
    if (data.company_name) {
      options['company_name'] = data.company_name;
    }

    if (data.phone_number) {
      options['phone_number'] = data.phone_number;
    }
    if (data.organization_id && !data.isUserProfileModal) {
      options['organization_id'] = data.organization_id;
    }
    if (_has(data, 'auto_approve_prospects')) {
      options['auto_approve_prospects'] = data.auto_approve_prospects;
    }
    if (_has(data, 'lock_prospect_users')) {
      options['lock_prospect_users'] = data.lock_prospect_users;
    }
    if (_has(data, 'blocked_organizations')) {
      options['blocked_organizations'] = data.blocked_organizations;
    }
    if (_has(data, 'lock_prospect_users')) {
      options['lock_prospect_users'] = data.lock_prospect_users;
    }

    if (_has(data, 'can_see_all_insight')) {
      options['can_see_all_insights'] = data.can_see_all_insight;
    }

    if (!environment.production) {
      url += `?errordetails=true`;
    }
    return this.http
      .patch(url, options)
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  resendUserInvite(user_id) {
    let url = `${this.appConfigService.getServerUrl()}/users/${user_id}/resend_invite`;
    if (!environment.production) {
      url += `?errordetails=true`;
    }
    return this.http
      .post(
        url,
        {},
        {
          headers: {
            'x-session-token': this.token.toString(),
          },
        }
      )
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  removeUser(user_id) {
    let url = `${this.appConfigService.getServerUrl()}/users/${user_id}`;
    if (!environment.production) {
      url += `?errordetails=true`;
    }
    return this.http
      .delete(url, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  getOrganizations(page_size = 200, current_page = 1) {
    let url = `${this.appConfigService.getServerUrl()}/organizations`;
    if (page_size) {
      url += `?page_size=${page_size}`;
    }
    if (current_page) {
      url += `&page=${current_page}`;
    }
    if (!environment.production) {
      url += `&errordetails=true`;
    }
    return this.http
      .get(url, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  getEmails(page_size = 200, current_page = 1) {
    let url = `${this.appConfigService.getServerUrl()}/emailsettings`;
    if (page_size) {
      url += `?page_size=${page_size}`;
    }
    if (current_page) {
      url += `&page=${current_page}`;
    }
    if (!environment.production) {
      url += `&errordetails=true`;
    }
    return this.http
      .get(url, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  updateEmail(email: any) {
    const url = `${this.appConfigService.getServerUrl()}/emailsettings`;
    return this.http
      .post(url, email, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  getSmsTemplates(page_size = 200, current_page = 1) {
    let url = `${this.appConfigService.getServerUrl()}/smssettings`;
    if (page_size) {
      url += `?page_size=${page_size}`;
    }
    if (current_page) {
      url += `&page=${current_page}`;
    }
    if (!environment.production) {
      url += `&errordetails=true`;
    }
    return this.http
      .get(url, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  updateSmsTemplate(sms: any) {
    const url = `${this.appConfigService.getServerUrl()}/smssettings`;
    return this.http
      .post(url, sms, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  getOrganization(organization_id) {
    let url = `${this.appConfigService.getServerUrl()}/organizations/${organization_id}`;
    if (!environment.production) {
      url += `?errordetails=true`;
    }
    return this.http
      .get(url, {
        headers: {},
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  createOrganization(data) {
    let url = `${this.appConfigService.getServerUrl()}/organizations`;
    if (!environment.production) {
      url += `?errordetails=true`;
    }
    return this.http
      .post(url, data, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  updateOrganization(data, organization_id) {
    let url = `${this.appConfigService.getServerUrl()}/organizations/${organization_id}`;
    let query = [];
    if (data.name) {
      query.push(`name=${data.name}`);
    }
    if (data.logo_url) {
      query.push(`logo_url=${data.logo_url}`);
    }
    if (query.length > 0) {
      url += `?${query.join('&')}`;
    }
    if (!environment.production) {
      url += `&errordetails=true`;
    }
    return this.http
      .patch(
        url,
        {},
        {
          headers: {
            'x-session-token': this.token.toString(),
          },
        }
      )
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  deleteOrganization(reference_id) {
    let url = `${this.appConfigService.getServerUrl()}/organizations/${reference_id}`;
    if (!environment.production) {
      url += `?errordetails=true`;
    }
    return this.http
      .delete(url, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  createReference(data) {
    let url = `${this.appConfigService.getServerUrl()}/references`;
    if (!environment.production) {
      url += `?errordetails=true`;
    }
    return this.http
      .post(url, data, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  updateReference(data, reference_id) {
    let url = `${this.appConfigService.getServerUrl()}/references/${reference_id}`;
    let query = [];
    if (data.created) {
      query.push(`created=${data.created}`);
    }
    if (data.title) {
      query.push(`title=${data.title}`);
    }
    if (data.description) {
      query.push(`description=${data.description}`);
    }
    if (data.sprout_video_id) {
      query.push(`sprout_video_id=${data.sprout_video_id}`);
    }
    if (typeof data.auto_approve_prospects !== 'undefined') {
      query.push(`auto_approve_prospects=${data.auto_approve_prospects}`);
    }
    if (typeof data.lock_prospect_users !== 'undefined') {
      query.push(`lock_prospect_users=${data.lock_prospect_users}`);
    }
    if (data.referee_user_id) {
      query.push(`referee_user_id=${data.referee_user_id}`);
    }
    if (data.referee_email_address) {
      query.push(
        `referee_email_address=${encodeURIComponent(
          data.referee_email_address
        )}`
      );
    }
    if (data.rqi) {
      query.push(`rqi=${data.rqi}`);
    }
    if (data.cta_text) {
      query.push(`cta_text=${data.cta_text}`);
    }
    if (data.cta_url) {
      query.push(`cta_url=${data.cta_url}`);
    }
    if (typeof data.is_reel !== 'undefined') {
      query.push(`is_reel=${data.is_reel}`);
    }
    if (query.length > 0) {
      url += `?${query.join('&')}`;
    }
    if (!environment.production) {
      url += `&errordetails=true`;
    }
    return this.http
      .patch(
        url,
        {},
        {
          headers: {
            'x-session-token': this.token.toString(),
          },
        }
      )
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  removeReference(reference_id) {
    let url = `${this.appConfigService.getServerUrl()}/references/${reference_id}`;
    if (!environment.production) {
      url += `?errordetails=true`;
    }
    return this.http
      .delete(url, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  resendReferenceNotification(reference_id, user_id) {
    let url = `${this.appConfigService.getServerUrl()}/users/${user_id}/references/${reference_id}/resend_notification`;
    if (!environment.production) {
      url += `?errordetails=true`;
    }
    return this.http
      .post(
        url,
        {},
        {
          headers: {
            'x-session-token': this.token.toString(),
          },
        }
      )
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  addProspectToReference(referenceId, data) {
    let url = `${this.appConfigService.getServerUrl()}/references/${referenceId}/prospects/${
      data.prospect_user_id
    }`;

    if (!environment.production) {
      url += `?errordetails=true`;
    }

    return this.http
      .post(
        url,
        { ...data, reference_id: referenceId },
        {
          headers: {
            'x-session-token': this.token.toString(),
          },
        }
      )
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  removeProspectFromReference(referenceId, prospectUserId) {
    let url = `${this.appConfigService.getServerUrl()}/references/${referenceId}/prospects/${prospectUserId}`;

    // if (!environment.production) {
    //   url += `&errordetails=true`;
    // }

    return this.http
      .delete(url, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  createReferenceTag(reference_id, data) {
    let url = `${this.appConfigService.getServerUrl()}/references/${reference_id}/tags`;
    if (!environment.production) {
      url += `?errordetails=true`;
    }
    return this.http
      .post(url, data, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  removeReferenceTag(reference_id, reference_tag_id) {
    let url = `${this.appConfigService.getServerUrl()}/references/${reference_id}/tags/${reference_tag_id}`;
    if (!environment.production) {
      url += `?errordetails=true`;
    }
    return this.http
      .delete(url, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  addReferenceTagValue(reference_id, reference_tag_id, data) {
    let url = `${this.appConfigService.getServerUrl()}/references/${reference_id}/tags/${reference_tag_id}`;
    if (!environment.production) {
      url += `?errordetails=true`;
    }
    return this.http
      .post(url, data, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  removeReferenceTagValue(
    reference_id,
    reference_tag_id,
    reference_tag_value_id
  ) {
    let url = `${this.appConfigService.getServerUrl()}/references/${reference_id}/tags/${reference_tag_id}/${reference_tag_value_id}`;
    if (!environment.production) {
      url += `?errordetails=true`;
    }
    return this.http
      .delete(url, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  getActivityLog(page_size = 200, current_page = 1) {
    let url = `${this.appConfigService.getServerUrl()}/activitylog`;
    if (page_size) {
      url += `?page_size=${page_size}`;
    }
    if (current_page) {
      url += `&page=${current_page}`;
    }
    if (!environment.production) {
      url += `&errordetails=true`;
    }
    return this.http
      .get(url, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }
}
