import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { timer } from 'rxjs';
import Utils from '@modules/shared/utils';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { TranslateService } from '@ngx-translate/core';

@AutoUnsubscribe()
@Component({
  selector: 'mgi-time-remaining',
  templateUrl: './time-remaining.component.html',
  styleUrls: ['./time-remaining.component.scss'],
})
export class TimeRemainingComponent implements OnInit, OnDestroy {
  @Input() accessEnd: any;
  @Input() hideDot: boolean = false;
  remaining: string = '';

  constructor(public translate: TranslateService) {}

  ngOnInit(): void {
    if (this.accessEnd) {
      // let endDate = new Date(this.accessEnd);
      // let seconds = endDate.getSeconds();
      // let startTime = (60 - seconds) * 1000;
      let time = Utils.getTimeRemaining(this.accessEnd);
      if (time.days) {
        let unitString;
        if (time.days === 1) {
          unitString = this.translate.instant('COMMON.TEXT.DAY');
        } else {
          unitString = this.translate.instant('COMMON.TEXT.DAYS');
        }
        this.remaining = `${this.translate.instant(
          'DASHBOARD.TIME_REMAINING'
        )} ${time.days} ${unitString}`;
      }
      // this.remaining = Utils.getTimeRemaining(this.accessEnd);
      // timer(startTime, 60000)
      //   .pipe()
      //   .subscribe((x) => {
      //     this.remaining = Utils.getTimeRemaining(this.accessEnd);
      //   });
    }
  }

  ngOnDestroy() {}
}
