import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface ITab {
  label: string;
  value: string;
}

@Component({
  selector: 'mgi-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  @Input() tabs: Array<ITab> = [];
  @Input() selectedTab: ITab | null = null;
  @Output() tabSelect = new EventEmitter<ITab>();

  constructor() {}

  ngOnInit(): void {}

  onTabClick(tab: ITab) {
    this.tabSelect.emit(tab);
  }
}
