import { Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { AuthState } from '@reducers/auth.reducer';
import { selectApp, selectAuth } from '@store/selectors';

@AutoUnsubscribe()
@Injectable()
export class AdminGuard implements CanActivate, OnDestroy {
  app$: Subscription;
  auth$: Subscription;
  appIsReady: boolean;
  isAuthenticated: boolean;
  isAdmin: boolean;
  userId: string;
  token: string;

  constructor(
    private router: Router,
    private store: Store<{ auth: AuthState }>
  ) {
    this.app$ = this.store.pipe(select(selectApp)).subscribe((app) => {
      this.appIsReady = app.isReady;
    });
    this.auth$ = this.store.pipe(select(selectAuth)).subscribe((auth) => {
      this.isAuthenticated = auth.authenticated;
      this.isAdmin = auth.isAdmin;
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise<boolean>((resolve) => {
      const authCheck = () => {
        if (this.isAuthenticated && this.isAdmin) {
          return resolve(true);
        } else if (this.isAuthenticated) {
          this.router.navigate(['dashboard']);
          return resolve(false);
        }

        window.localStorage.setItem('returnUrl', state.url);
        this.router.navigate(['login'], {
          queryParams: { returnUrl: state.url },
        });

        return resolve(false);
      };

      if (this.appIsReady) {
        return authCheck();
      }

      // if the app is not ready wait for the app sub
      const sub$ = this.store.pipe(select(selectApp)).subscribe((app) => {
        if (app.isReady) {
          sub$.unsubscribe();
          return authCheck();
        }
      });
    });
  }

  ngOnDestroy() {}
}
