import { Injectable, OnDestroy } from '@angular/core';

import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

@Injectable()
export class LoginProxyGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const params = new URLSearchParams(window.location.search);

    const orgId = params.get('org_id');

    if (orgId) {
      this.router.navigateByUrl(`/login?org_id=${orgId}`);
    } else {
      this.router.navigateByUrl('/login');
    }

    return false;
  }
}
