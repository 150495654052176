import { Action, createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { AuthActions } from '@actions/auth.actions';

export interface IProfileOrg {
  id: string;
  logoUrl: string;
  name: string;
  referenceCount: number;
}

export type TRoles = 'seller' | 'prospect' | 'referee' | '';

export interface AuthState {
  authenticated: boolean;
  token?: string;
  userId?: string;
  isProspect: boolean;
  isReferee: boolean;
  isSeller: boolean;
  isAdmin: boolean;
  showTerms?: boolean;
  profile: {
    data: {
      user_id?: string;
      email_address: string;
      first_name: string;
      last_name: string;
      linkedin_url: string;
      reference_roles?: Array<'seller' | 'prospect' | 'referee'>;
      is_admin: boolean;
      is_registered: boolean;
      auto_approve_prospects?: boolean;
      blocked_organizations?: Array<any>;
      referee_distribution_settings_updated: string;
      organizations: Array<IProfileOrg>;
      can_see_all_insights: boolean;
    };
    isFetching: boolean;
    lastUpdated?: string;
    error?: Error;
    modal: {
      visible: boolean;
      isFirstTime?: boolean;
    };
  };
  login: {
    isFetching: boolean;
    lastUpdated?: string;
    error?: Error;
  };
  logout: {
    isFetching: boolean;
    error?: Error;
  };
  session: {
    isFetching: boolean;
  };
  selectedOrgId: string;
  selectedRole: TRoles;
}

export const initialState: AuthState = {
  authenticated: false,
  isProspect: false,
  isReferee: false,
  isAdmin: false,
  isSeller: false,
  profile: {
    data: {
      email_address: '',
      first_name: '',
      last_name: '',
      linkedin_url: '',
      is_admin: false,
      is_registered: false,
      referee_distribution_settings_updated: '',
      reference_roles: [],
      organizations: [],
      can_see_all_insights: false,
    },
    isFetching: false,
    modal: {
      visible: false,
      isFirstTime: false,
    },
  },
  login: {
    isFetching: false,
  },
  logout: {
    isFetching: false,
  },
  session: {
    isFetching: false,
  },
  selectedOrgId: '',
  selectedRole: '',
};

const login = produce((draft) => {
  draft.login.isFetching = true;
});

const loginSuccess = produce((draft, { payload }) => {
  draft.authenticated = true;
  draft.token = payload.session_token;
  draft.login.isFetching = false;
  draft.login.lastUpdated = new Date().toISOString();
});

const loginError = produce((draft, action) => {
  draft.authenticated = false;
  draft.token = null;
  draft.login.error = action.error;
  draft.login.isFetching = false;
});

const clearToken = produce((draft) => {
  draft.authenticated = false;
  draft.token = null;
  draft.userId = null;
});

const logout = produce((draft) => {
  draft.logout.isFetching = true;
});

const logoutSuccess = produce((draft) => {
  draft.authenticated = false;
  draft.token = null;
  draft.userId = null;
  draft.logout.isFetching = false;

  draft.isProspect = false;
  draft.isReferee = false;
  draft.isAdmin = false;
  draft.isSeller = false;
  draft.profile = {
    data: {
      email_address: '',
      first_name: '',
      last_name: '',
      linkedin_url: '',
      is_admin: false,
      is_registered: false,
      referee_distribution_settings_updated: '',
      reference_roles: [],
      organizations: [],
    },
    isFetching: false,
    modal: {
      visible: false,
      isFirstTime: false,
    },
  };
  draft.selectedOrgId = '';

  window.localStorage.setItem('defaultOrgId', '');
});

const logoutError = produce((draft) => {
  draft.authenticated = false;
  draft.token = null;
  draft.userId = null;
  draft.logout.isFetching = false;
});

const getProfile = produce((draft, { token }) => {
  draft.profile.isFetching = true;
  draft.token = token;
  draft.showTerms = false;
});

const setProfile = produce((draft, { payload }) => {
  const roles = payload.reference_roles;

  draft.authenticated = true;
  draft.userId = payload.user_id;

  draft.profile.data = {
    ...draft.profile.data,
    ...payload,
  };

  if (roles && roles.length) {
    if (roles.includes('prospect')) {
      draft.isProspect = true;
    }
    if (roles.includes('referee')) {
      draft.isReferee = true;
    }
    if (roles.includes('seller')) {
      draft.isSeller = true;
    }
  }

  if (payload.is_admin) {
    draft.isAdmin = true;
  }

  draft.profile.isFetching = false;
  draft.profile.lastUpdated = new Date().toISOString();
});

const getProfileError = produce((draft, action) => {
  draft.authenticated = false;

  if (
    (action && action.error === 'legal_prospect_terms_of_service_error') ||
    action.error === 'legal_referee_terms_of_service_error' ||
    action.error === 'legal_seller_terms_of_service_error'
  ) {
    draft.showTerms = true;
  } else {
    draft.token = null;
    draft.userId = null;
  }
  draft.profile.error = action.error;
  draft.profile.isFetching = false;
});

const updateProfile = produce((draft) => {
  draft.profile.isFetching = true;
});

const updateProfileError = produce((draft) => {
  draft.profile.isFetching = false;
});

const showProfileModal = produce((draft, action) => {
  draft.profile.modal.visible = true;
  draft.profile.modal.isFirstTime = action.isFirstTime;
});

const hideProfileModal = produce((draft) => {
  draft.profile.modal.visible = false;
});

const sessionCheck = produce((draft, { token }) => {
  draft.session.isFetching = true;
});

const sessionCheckSuccess = produce((draft, { token }) => {
  draft.session.isFetching = false;
});

const sessionCheckError = produce((draft, { token }) => {
  draft.authenticated = false;
  draft.token = null;
  draft.userId = null;
  draft.logout.isFetching = false;
  draft.session.isFetching = false;
});

const setSelectedOrg = produce((draft, { selectedOrgId }) => {
  draft.selectedOrgId = selectedOrgId;

  if (selectedOrgId) {
    window.localStorage.setItem('defaultOrgId', selectedOrgId);
  }
});

const setSelectedRole = produce((draft, { role }) => {
  draft.selectedRole = role;
  draft.selectedOrgId = '';
});

const authReducer = createReducer(
  initialState,
  on(AuthActions.login, login),
  on(AuthActions.loginSuccess, loginSuccess),
  on(AuthActions.loginError, loginError),
  on(AuthActions.clearToken, clearToken),
  on(AuthActions.logout, logout),
  on(AuthActions.logoutSuccess, logoutSuccess),
  on(AuthActions.logoutError, logoutError),
  on(AuthActions.getProfile, getProfile),
  on(AuthActions.getProfileSuccess, setProfile),
  on(AuthActions.getProfileError, getProfileError),
  on(AuthActions.setProfile, setProfile),
  on(AuthActions.updateProfile, updateProfile),
  on(AuthActions.updateProfileError, updateProfileError),
  on(AuthActions.showProfileModal, showProfileModal),
  on(AuthActions.hideProfileModal, hideProfileModal),
  on(AuthActions.sessionCheck, sessionCheck),
  on(AuthActions.sessionCheckSuccess, sessionCheckSuccess),
  on(AuthActions.sessionCheckError, sessionCheckError),
  on(AuthActions.setSelectedOrg, setSelectedOrg),
  on(AuthActions.setSelectedRole, setSelectedRole)
);

export function reducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}
