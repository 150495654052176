<section fxLayout="row" class="footer" [class]="className">
  <div fxLayout="row" class="left-section">
    <div>
      <svg-icon
        class="logo-icon"
        src="/assets/images/icons/verified-logo.svg"
      ></svg-icon>
    </div>
    <div class="copyright-section" fxHide.lt-sm>
      <div>
        <mgi-button class="" size="small" type="link" (click)="goPolicyPage()">
          {{ 'COMMON.TEXT.PRIVACY_POLICY' | translate }}
        </mgi-button>
        &middot;
        <mgi-button
          className=""
          size="small"
          type="link"
          (click)="onSupportClick()"
        >
          {{ 'COMMON.TEXT.SUPPORT' | translate }}
        </mgi-button>
        &middot;
        <mgi-button
          className=""
          size="small"
          type="link"
          (click)="goAboutPage()"
        >
          {{ 'COMMON.TEXT.ABOUT_US' | translate }}
        </mgi-button>
        <mgi-support-modal
          [visible]="isSupportVisible"
          (onCancel)="hideSupportModal()"
        >
        </mgi-support-modal>
      </div>
      <div class="copyright">
        &copy; {{ year }} {{ 'COPYRIGHT.MGI' | translate }}
      </div>
    </div>
  </div>
  <div fxFlex fxLayoutAlign="center start" class="right-section">
    <div>
      <div fxHide fxShow.lt-sm class="links-section">
        <mgi-button class="" size="small" type="link" (click)="goPolicyPage()">
          {{ 'COMMON.TEXT.PRIVACY_POLICY' | translate }}
        </mgi-button>
        &middot;
        <mgi-button
          className=""
          size="small"
          type="link"
          (click)="onSupportClick()"
        >
          {{ 'COMMON.TEXT.SUPPORT' | translate }}
        </mgi-button>
        &middot;
        <mgi-button
          className=""
          size="small"
          type="link"
          (click)="goAboutPage()"
        >
          {{ 'COMMON.TEXT.ABOUT_US' | translate }}
        </mgi-button>
        <mgi-support-modal
          [visible]="isSupportVisible"
          (onCancel)="hideSupportModal()"
        >
        </mgi-support-modal>
      </div>
      <div class="phone">
        <svg-icon
          class="phone-icon"
          src="/assets/images/icons/phone.svg"
        ></svg-icon>
        <span fxHide fxShow.lt-sm>
          <a href="tel:1.800.813.0073">1.800.813.0073</a>
        </span>
        <span fxHide.lt-sm>1.800.813.0073</span>
      </div>
      <div class="mail">
        <a href="mailto:sales@mgiverified.com"
          ><span>sales@mgiverified.com</span></a
        >
      </div>
      <div class="address">
        560 Sylvan Avenue, Suite 3160, Englewood Cliffs, NJ 07632
      </div>
      <div fxHide fxShow.lt-sm class="copyright">
        &copy; {{ year }} {{ 'COPYRIGHT.MGI' | translate }}
      </div>
    </div>
  </div>
</section>
