import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';

@Component({
  selector: 'mgi-ref-tags',
  templateUrl: './ref-tags.component.html',
  styleUrls: ['./ref-tags.component.scss'],
})
export class RefTagsComponent implements OnInit {
  @Input() tags: any;
  @Input() fixedMiniumTagNumber: number = -1;
  combinedTagsWidth: any = [];
  showTagNumber: number = 1;
  miniumTagNumber: number = 1;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    let combinedTags = [];
    this.combinedTagsWidth = [];

    if (this.fixedMiniumTagNumber !== -1) {
      this.miniumTagNumber = this.fixedMiniumTagNumber;
      this.showTagNumber = this.fixedMiniumTagNumber;
      return;
    }

    for (let i = 0; i < this.tags.length; i++) {
      const tag = this.tags[i];
      combinedTags[i] = `${tag.name}: `;
      for (const key in tag.values) {
        if (Object.prototype.hasOwnProperty.call(tag.values, key)) {
          const value = tag.values[key];
          combinedTags[i] += ` ${value}`;
        }
      }
      if (combinedTags[i].length) {
        this.combinedTagsWidth[i] = this.measureText(combinedTags[i]);
      }
    }
  }

  @ViewChild('tagsDiv')
  tagsDiv: ElementRef;

  sizing() {
    let parentWidth = this.tagsDiv.nativeElement.offsetWidth;
    let width = 0;
    if (this.fixedMiniumTagNumber !== -1) {
      return;
    }
    for (let i = 0; i < this.combinedTagsWidth.length; i++) {
      const childWidth = this.combinedTagsWidth[i];
      width += childWidth + 24; //with padding and margin
      console.log(width);
      if (width >= parentWidth && i > 1) {
        this.showTagNumber = i - 1;
        this.miniumTagNumber = this.showTagNumber;
        this.cd.detectChanges();
        break;
      }
      if (i === this.combinedTagsWidth.length - 1 && parentWidth > width) {
        this.showTagNumber = i + 1;
        this.miniumTagNumber = this.showTagNumber;
        this.cd.detectChanges();
      }
    }
  }

  showAllTags() {
    this.showTagNumber = this.tags.length;
  }

  showLessTags() {
    this.showTagNumber = this.miniumTagNumber;
  }

  measureText(str, fontSize = 10) {
    const widths = [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0.2796875, 0.2765625, 0.3546875, 0.5546875,
      0.5546875, 0.8890625, 0.665625, 0.190625, 0.3328125, 0.3328125, 0.3890625,
      0.5828125, 0.2765625, 0.3328125, 0.2765625, 0.3015625, 0.5546875,
      0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875,
      0.5546875, 0.5546875, 0.5546875, 0.2765625, 0.2765625, 0.584375,
      0.5828125, 0.584375, 0.5546875, 1.0140625, 0.665625, 0.665625, 0.721875,
      0.721875, 0.665625, 0.609375, 0.7765625, 0.721875, 0.2765625, 0.5,
      0.665625, 0.5546875, 0.8328125, 0.721875, 0.7765625, 0.665625, 0.7765625,
      0.721875, 0.665625, 0.609375, 0.721875, 0.665625, 0.94375, 0.665625,
      0.665625, 0.609375, 0.2765625, 0.3546875, 0.2765625, 0.4765625, 0.5546875,
      0.3328125, 0.5546875, 0.5546875, 0.5, 0.5546875, 0.5546875, 0.2765625,
      0.5546875, 0.5546875, 0.221875, 0.240625, 0.5, 0.221875, 0.8328125,
      0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.3328125, 0.5, 0.2765625,
      0.5546875, 0.5, 0.721875, 0.5, 0.5, 0.5, 0.3546875, 0.259375, 0.353125,
      0.5890625,
    ];
    const avg = 0.5279276315789471;
    return (
      str
        .split('')
        .map((c) =>
          c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg
        )
        .reduce((cur, acc) => acc + cur) * fontSize
    );
  }
}
