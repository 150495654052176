import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '@modules/shared/shared.module';
import { FormlyFieldAutocompleteComponent } from './components/formly-field-autocomplete/formly-field-autocomplete.component';
import { FormlyFieldCustomInput } from './components/formly-field-custom-input/formly-field-custom-input.component';
import { FormlyWrapperCustomFormField } from './components/formly-wrapper-custom-form-field/formly-wrapper-custom-form-field.component';

@NgModule({
  declarations: [
    FormlyFieldAutocompleteComponent,
    FormlyWrapperCustomFormField,
    FormlyFieldCustomInput,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    FormlyModule.forRoot({
      types: [
        {
          name: 'autocomplete',
          component: FormlyFieldAutocompleteComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'custominput',
          component: FormlyFieldCustomInput,
          wrappers: ['custom-form-field'],
        },
      ],
      wrappers: [
        {
          name: 'custom-form-field',
          component: FormlyWrapperCustomFormField,
        },
      ],
    }),
  ],
})
export class CustomFormlyModule {}
