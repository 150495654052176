import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { DeviceSizeService } from '@services/device-size.service';
import { Store, select } from '@ngrx/store';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { AuthState } from '@reducers/auth.reducer';
import { selectAuth } from '@store/selectors';

@AutoUnsubscribe()
@Component({
  selector: 'mgi-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageLayoutComponent implements OnInit, OnDestroy {
  auth$: Subscription;
  isAuthenticated: boolean;
  constructor(
    private deviceSizeService: DeviceSizeService,
    private store: Store<{ auth: AuthState }>
  ) {
    this.auth$ = this.store.pipe(select(selectAuth)).subscribe((auth) => {
      this.isAuthenticated = auth.authenticated;
    });
  }

  ngOnInit(): void {}

  isMobile() {
    return this.deviceSizeService.isSmallScreen();
  }

  ngOnDestroy(): void {}
}
