<nz-modal
  nzClassName="user-profile-modal"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  [nzVisible]="isVisible"
  (nzOnCancel)="handleClose()"
  nzMaskClosable="false"
  [nzWidth]="modalWidth"
>
  <ng-template #modalContent>
    <mgi-form
      [enableFooter]="false"
      [model]="profile"
      [schema]="fields"
    ></mgi-form>
    <div *ngIf="isFetching" fxLayout fxLayoutAlign="center center">
      <i nz-icon [nzType]="'loading'" style="font-size: 30px;"></i>
    </div>
    <div *ngIf="!isFetching && isReferee">
      <mgi-blocked-organizations
        [blockedcOrganizations]="blockedOrganizations"
        [hideHeader]="true"
        (addNewItem)="addBlockedOrganizations($event)"
        (removeItem)="removeBlockedOrganization($event)"
      ></mgi-blocked-organizations>
    </div>
  </ng-template>
  <ng-template #modalFooter>
    <div
      fxLayout="row"
      fxLayout.lt-sm="column"
      fxLayoutAlign="space-between center"
    >
      <mgi-button size="medium" className="modal-btn" (click)="handleUpdate()">
        {{ 'COMMON.TEXT.APPLY_CHANGES' | translate }}
      </mgi-button>
      <mgi-button
        type="red"
        size="medium"
        className="modal-btn cancel"
        (click)="handleClose()"
      >
        {{ 'COMMON.TEXT.CANCEL_CHANGES' | translate }}
      </mgi-button>
    </div>
  </ng-template>
</nz-modal>
