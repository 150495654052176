import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'mgi-formly-field-custom-input',
  templateUrl: './formly-field-custom-input.component.html',
  styleUrls: ['./formly-field-custom-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldCustomInput extends FieldType {}
