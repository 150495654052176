import {
  Component,
  OnInit,
  Input,
  Output,
  OnDestroy,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Utils from '@modules/shared/utils';

@Component({
  selector: 'mgi-blocked-organizations',
  templateUrl: './blocked-organizations.component.html',
  styleUrls: ['./blocked-organizations.component.scss'],
})
export class BlockedOrganizationsComponent implements OnInit, OnDestroy {
  @Input() blockedcOrganizations: any;
  @Input() mode: any = 'edit';
  @Input() hideHeader: boolean = false;
  @Output() addNewItem = new EventEmitter<any>();
  @Output() removeItem = new EventEmitter<any>();
  newCompanyName: any;
  newCompanyDomain: any;
  errorMsg: any = false;
  constructor(public translate: TranslateService) {}

  ngOnInit(): void {}

  addBlockedOrganizations() {
    this.errorMsg = false;
    if (this.newCompanyName && this.newCompanyDomain) {
      if (Utils.isDomain(this.newCompanyDomain)) {
        this.addNewItem.emit({
          name: this.newCompanyName,
          domain: this.newCompanyDomain,
        });
      } else {
        this.errorMsg = this.translate.instant('COMMON.TEXT.INVALID_DOMAIN');
      }
      this.newCompanyName = '';
      this.newCompanyDomain = '';
    }
  }

  handleRemoveItem(i) {
    this.removeItem.emit(i);
  }

  ngOnDestroy() {}
}
