import { Component, Input, OnInit, OnDestroy, DoCheck } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store, select } from '@ngrx/store';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { AuthActions } from '@actions/auth.actions';
import { StatsActions } from '../../../dashboard/actions/stats.actions';
import { ProspectsActions } from '../../../dashboard/actions/prospects.actions';
import { selectAuth, selectProspects } from '@store/selectors';
import { Router, NavigationEnd } from '@angular/router';
import { DashboardService } from '@services/dashboard.service';
import { IProfileOrg, TRoles } from '@reducers/auth.reducer';
import { UntypedFormGroup } from '@angular/forms';
import { AdminService } from '@services/admin.service';
import { Constants } from '@modules/shared/constants';

const defaultOption = {
  id: 'all',
  logoUrl: '/assets/images/verified.svg',
  name: 'View all companies',
};

@AutoUnsubscribe()
@Component({
  selector: 'mgi-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy, DoCheck {
  auth$: Subscription;
  prospects$: Subscription;
  isFetching = false;
  token: string;
  showDashboardType: boolean;
  @Input() isCollapsed: boolean;
  subscription: any;
  profile: any;
  isProfileFetching: boolean;
  displayText: string;
  isShowProfile = false;
  isProspect = false;
  isReferee = false;
  isSeller = false;
  isAdmin = false;
  numberOfPending = 0;
  pendingProspects: any = [];
  selectedOrg: IProfileOrg | null = null;
  isOrgListVisible = false;
  isInviteModalOpen = false;
  totalRefCount = 0;
  isOrgSelectorVisible = false;
  selectedRole: TRoles;
  invitationLinks: Array<{
    email: string;
    link: string;
  }> = [];
  modalWidth: number = Constants.DEFAULT_MODAL_WIDTH;
  loaderClass = '';

  constructor(
    private store: Store,
    public translate: TranslateService,
    private dashboardService: DashboardService,
    private router: Router,
    private adminService: AdminService
  ) {
    this.prospects$ = this.store
      .pipe(select(selectProspects))
      .subscribe((prospects) => {
        if (prospects) {
          this.numberOfPending = prospects.total_items;
          this.pendingProspects = prospects.data;
        }
      });

    this.auth$ = this.store.pipe(select(selectAuth)).subscribe((auth) => {
      this.token = auth.token;
      this.profile = auth.profile.data;
      this.isProspect = auth.isProspect;
      this.isReferee = auth.isReferee;
      this.isSeller = auth.isSeller;
      this.isAdmin = auth.isAdmin;
      this.selectedRole = auth.selectedRole;
      this.isProfileFetching = auth.profile.isFetching;

      this.totalRefCount = this.profile.organizations.reduce(
        (acc, curr) => acc + curr.referenceCount,
        0
      );

      const organizations = [defaultOption, ...this.profile.organizations];
      this.selectedOrg = organizations.find(
        (org) => org.id === auth.selectedOrgId
      );

      if (this.profile.first_name && this.profile.last_name) {
        this.displayText = `${this.profile.first_name.charAt(
          0
        )}${this.profile.last_name.charAt(0)}`;
      }

      const urlParam = getParameterByName('orgId');
      const defaultOrgId =
        urlParam ?? window.localStorage.getItem('defaultOrgId');

      // select one org from the localstorage if there is none selected
      if (!this.selectedOrg && defaultOrgId) {
        const selectedOrg = [defaultOption, ...this.profile.organizations].find(
          (org) => org.id === defaultOrgId
        );

        if (selectedOrg) {
          this.store.dispatch(
            AuthActions.setSelectedOrg({
              selectedOrgId: selectedOrg.id,
            })
          );
        } else {
          window.localStorage.setItem('defaultOrgId', '');
        }
      }

      // if there is only one org, select the default one
      if (!this.selectedOrg && this.profile.organizations.length === 1) {
        this.store.dispatch(
          AuthActions.setSelectedOrg({
            selectedOrgId: this.profile.organizations[0].id,
          })
        );
      }

      // validate reference role
      if (this.selectedRole) {
        const isValidRole = this.getIsValidRole();
        if (!isValidRole) {
          this.store.dispatch(
            AuthActions.setSelectedRole({
              role: '',
            })
          );
        }
      }

      // select role if nothing is selected
      if (!this.selectedRole && !this.isAdmin) {
        const type = this.getRole();
        if (type) {
          this.openDashboardWithType(type);
        }
      }
    });

    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showDashboardType =
          event.url === '/dashboard' && this.profile.reference_roles.length;
      }
    });
  }

  ngOnInit(): void {
    // if (location.hash === '#/dashboard') {
    //   this.showDashboardType = !!this.profile.reference_roles.length;
    // }
  }

  ngDoCheck() {
    const isInsightsPage = this.router.url === '/dashboard/insights';
    this.isOrgSelectorVisible =
      (this.selectedRole === 'prospect' || this.selectedRole === 'seller') &&
      !this.isAdmin &&
      !isInsightsPage &&
      !this.isProfileFetching;
  }

  getIsValidRole() {
    if (this.selectedRole === 'seller' && this.isSeller) {
      return true;
    }

    if (this.selectedRole === 'prospect' && this.isProspect) {
      return true;
    }

    if (this.selectedRole === 'referee' && this.isReferee) {
      return true;
    }

    return false;
  }

  getRole() {
    if (this.isSeller) {
      return 'seller';
    }

    if (this.isProspect) {
      return 'prospect';
    }

    if (this.isReferee) {
      return 'referee';
    }

    return '';
  }

  goDashboard() {
    this.router.navigate(['dashboard']);
  }

  openDashboardWithType(role: TRoles) {
    if (this.selectedRole !== role) {
      this.store.dispatch(
        AuthActions.setSelectedRole({
          role,
        })
      );
      if (role === 'seller') {
        this.store.dispatch(StatsActions.getSellerStats());
      }
      this.store.dispatch(AuthActions.getProfile({ token: this.token }));
      this.goDashboard();
    }
    this.profileVisibleChange();
  }

  profileVisibleChange() {
    this.isShowProfile = !this.isShowProfile;
  }

  onProfileBtnClick() {
    this.isShowProfile = !this.isShowProfile;
  }

  openPendingProspects() {
    this.isShowProfile = !this.isShowProfile;
    this.store.dispatch(
      ProspectsActions.getPendingProspects({
        page_size: 50,
      })
    );
    this.store.dispatch(
      ProspectsActions.togglePendingProspectsModal({ payload: { show: true } })
    );
  }

  doLogout() {
    this.store.dispatch(
      AuthActions.logout({
        token: this.token.toString(),
      })
    );
  }

  openPreferences() {
    this.isShowProfile = !this.isShowProfile;
    this.store.dispatch(
      AuthActions.showProfileModal({
        isFirstTime: false,
      })
    );
  }

  handleApprove(prospect) {
    this.dashboardService
      .approvePendingProspect(prospect.reference_id, prospect.prospect_user_id)
      .subscribe(() => {
        this.store.dispatch(
          ProspectsActions.getPendingProspects({
            page_size: 50,
          })
        );
      });
  }

  handleReject(prospect) {
    this.dashboardService
      .rejectPendingProspect(prospect.reference_id, prospect.prospect_user_id)
      .subscribe(() => {
        this.store.dispatch(
          ProspectsActions.getPendingProspects({
            page_size: 50,
          })
        );
      });
  }

  onOrgSelect(org: any) {
    this.store.dispatch(
      AuthActions.setSelectedOrg({
        selectedOrgId: org.id,
      })
    );
    window.localStorage.setItem('defaultOrgId', org.id);
  }

  onToggleOrgList() {
    this.isOrgListVisible = !this.isOrgListVisible;
  }

  onCreateSellerUser(form: UntypedFormGroup) {
    if (form.status !== 'VALID') {
      // display the error messages
      Object.values(form.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });

      return;
    }

    const data = {
      email_address: form.value.email_address,
      first_name: form.value.first_name,
      last_name: form.value.last_name,
      is_seller: true,
      organization_id: this.selectedOrg.id,
    };

    this.isFetching = true;
    this.isInviteModalOpen = false;

    this.adminService.createUser(data).subscribe(
      (res) => {
        this.loaderClass = 'success';
        this.isFetching = false;
        this.invitationLinks = Object.keys(res.users).map((key) => ({
          email: key,
          link: res.users[key].item2,
        }));
        if (
          this.invitationLinks &&
          this.invitationLinks.some((x) => !x.link || x.link === '')
        ) {
          this.closeModal();
        }
        form.reset();
      },
      (res) => {
        console.log(res);
        form.reset();
      }
    );
  }

  onCopy(link: string) {
    navigator.clipboard.writeText(link);
  }

  closeModal() {
    this.isInviteModalOpen = false;
    this.invitationLinks = [];
  }

  ngOnDestroy(): void {}
}

function getParameterByName(paramName: string, url = window.location.href) {
  const name = paramName.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
