import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { AdminService } from '@services/admin.service';
import { OrganizationActions } from '../actions/organization.actions';

@Injectable()
export class OrganizationEffects {
  constructor(private actions$: Actions, private adminService: AdminService) {}

  getOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.getOrganization),
      mergeMap((action) =>
        this.adminService.getOrganization(action.organization_id).pipe(
          map((organization) =>
            OrganizationActions.getOrganizationSuccess({
              payload: organization,
            })
          ),
          catchError((error) =>
            of(OrganizationActions.getOrganizationError({ error }))
          )
        )
      )
    )
  );
}
