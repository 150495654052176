import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@guards/auth.guard';
import { AdminGuard } from '@guards/admin.guard';
import { LoginProxyGuard } from '@guards/login-proxy.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('@modules/login/login.module').then((m) => m.LoginModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'session',
    loadChildren: () =>
      import('@modules/session/session.module').then((m) => m.SessionModule),
  },
  {
    path: 'invite',
    loadChildren: () =>
      import('@modules/invite/invite.module').then((m) => m.InviteModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'confirm',
    loadChildren: () =>
      import('@modules/confirm/confirm.module').then((m) => m.ConfirmModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'resetPassword',
    loadChildren: () =>
      import('@modules/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('@modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'reel',
    loadChildren: () =>
      import('@modules/reel/reel.module').then((m) => m.ReelModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('@modules/admin-dashboard/admin-dashboard.module').then(
        (m) => m.AdminDashboardModule
      ),
    canActivate: [AdminGuard],
  },
  {
    path: 'policy',
    loadChildren: () =>
      import('@modules/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  {
    path: 'loginlegals',
    loadChildren: () =>
      import('@modules/login-legals/login-legals.module').then(
        (m) => m.LoginLegalsModule
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('@modules/login-legals/login-legals.module').then(
        (m) => m.LoginLegalsModule
      ),
    canActivate: [LoginProxyGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
