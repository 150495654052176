import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minuteSeconds',
})
export class MinuteSecondsPipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    if (minutes == 0) {
      return `${value}s`;
    }
    return `${minutes}m ${value - minutes * 60}s`;
  }
}
