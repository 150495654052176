import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppConfigService } from '@services/app-config.service';
import { AuthState } from '@reducers/auth.reducer';
import { selectAuth } from '@store/selectors';

import { HttpClient } from '@angular/common/http';
import { AppService } from '@services/app.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';

interface shareReferenceData {
  first_name?: string;
  last_name?: string;
  email_address: string;
}

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  auth$: Subscription;
  token: String;
  isAuthenticated: boolean;
  constructor(
    private appConfigService: AppConfigService,
    private appService: AppService,
    private http: HttpClient,
    private store: Store<{ auth: AuthState }>,
    private router: Router
  ) {
    this.auth$ = this.store.pipe(select(selectAuth)).subscribe((auth) => {
      this.token = auth.token;
    });
  }

  getReferences(options) {
    const { type, page_size, current_page, tags, search_text, org_id } =
      options;

    const url = `${this.appConfigService.getServerUrl()}/referenceSearch`;

    const data = {
      page: current_page ?? 1,
      page_size: page_size ?? 25,
      reference_user_role: type,
      ...(org_id && type !== 'referee' && { org_id }),
      ...(search_text?.length && { search_texts: search_text }),
      ...(tags && { tags }),
    };

    return this.http
      .post(url, data, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  getReference(reference_id) {
    let url = `${this.appConfigService.getServerUrl()}/references/${reference_id}`;
    if (!environment.production) {
      url += `?errordetails=true`;
    }
    return this.http
      .get(url, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  getReferenceLegal(reference_id) {
    return this.http
      .get(
        `${this.appConfigService.getServerUrl()}/references/${reference_id}/legal`,
        {
          headers: {
            'x-session-token': this.token.toString(),
          },
        }
      )
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  getReferenceLegalSummary(reference_id) {
    return this.http
      .get(
        `${this.appConfigService.getServerUrl()}/references/${reference_id}/legal_summary`,
        {
          headers: {
            'x-session-token': this.token.toString(),
          },
        }
      )
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  getReferenceVideo(referenceId: string, referenceAccessToken?: string) {
    let url = `${this.appConfigService.getServerUrl()}/references/${referenceId}/video`;

    return this.http
      .get(url, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  acceptReferenceLegal(reference_id, legal_id) {
    return this.http
      .post(
        `${this.appConfigService.getServerUrl()}/references/${reference_id}/legals/${legal_id}`,
        {},
        {
          headers: {
            'x-session-token': this.token.toString(),
          },
        }
      )
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  shareReference(reference_id, data: shareReferenceData) {
    return this.http
      .post(
        `${this.appConfigService.getServerUrl()}/references/${reference_id}/share`,
        data,
        {
          headers: {
            'x-session-token': this.token.toString(),
          },
        }
      )
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  contactSupport(data) {
    return this.http
      .post(`${this.appConfigService.getServerUrl()}/contact`, data, {})
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  getPendingProspects(options) {
    let { reference_id, page_size, current_page } = options;
    let url = `${this.appConfigService.getServerUrl()}/references/pending_prospects`;
    if (!page_size) {
      page_size = 50;
    }
    url += `?page_size=${page_size}`;
    if (!current_page) {
      current_page = 1;
    }
    url += `&page=${current_page}`;

    if (reference_id) {
      url += `&reference_id=${reference_id}`;
    }

    return this.http
      .get(url, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  approvePendingProspect(reference_id, prospect_user_id) {
    return this.http
      .post(
        `${this.appConfigService.getServerUrl()}/references/${reference_id}/prospects/${prospect_user_id}/approve`,
        {},
        {
          headers: {
            'x-session-token': this.token.toString(),
          },
        }
      )
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  rejectPendingProspect(reference_id, prospect_user_id) {
    return this.http
      .delete(
        `${this.appConfigService.getServerUrl()}/references/${reference_id}/prospects/${prospect_user_id}/reject`,
        {
          headers: {
            'x-session-token': this.token.toString(),
          },
        }
      )
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  getLoginLegals() {
    return this.http
      .get(`${this.appConfigService.getServerUrl()}/legals`, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  acceptLoginLegals(data: any) {
    return this.http
      .post(`${this.appConfigService.getServerUrl()}/legals`, data, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  getSellerStats() {
    return this.http
      .get(`${this.appConfigService.getServerUrl()}/seller/stats`, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  getSearchTags() {
    return this.http
      .get(`${this.appConfigService.getServerUrl()}/searchTags`, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  getOrganizationStats() {
    return this.http
      .get(`${this.appConfigService.getServerUrl()}/statistics`, {
        headers: {
          'x-session-token': this.token.toString(),
        },
      })
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  getProspectStats(action: any) {
    return this.http
      .get(
        `${this.appConfigService.getServerUrl()}/statistics/user/${
          action.user_id
        }/${action.org_id}?view_all=${action.view_all}`,
        {
          headers: {
            'x-session-token': this.token.toString(),
          },
        }
      )
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  getRefStats(action: any) {
    return this.http
      .get(
        `${this.appConfigService.getServerUrl()}/statistics/${action.user_id}/${
          action.ref_id
        }`,
        {
          headers: {
            'x-session-token': this.token.toString(),
          },
        }
      )
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  reportRefWatched(action: any) {
    return this.http
      .post(
        `${this.appConfigService.getServerUrl()}/activitylog`,
        {
          ConnectedItemId: action.ref_id,
          Type: 1,
        },
        {
          headers: {
            'x-session-token': this.token.toString(),
          },
        }
      )
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }

  reportQuickLinkClicked(action: any) {
    return this.http
      .post(
        `${this.appConfigService.getServerUrl()}/activitylog`,
        {
          ConnectedItemId: action.ref_id,
          Type: 2,
        },
        {
          headers: {
            'x-session-token': this.token.toString(),
          },
        }
      )
      .pipe(catchError(this.appService.handleApiError.bind(this)));
  }
}
