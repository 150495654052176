import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mgi-video-thumbnail',
  templateUrl: './video-thumbnail.component.html',
  styleUrls: ['./video-thumbnail.component.scss'],
})
export class VideoThumbnailComponent implements OnInit {
  @Input() src: string;
  // @Input() ratio = '1.78/1';
  // @Input() ratio = '2.1336/1';
  @Input() showPlayBtn: boolean = false;
  @Input() status: any;
  constructor() {}

  ngOnInit(): void {
    console.log(this.status);
  }
}
