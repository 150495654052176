import { Component, Input, OnChanges, OnInit } from '@angular/core';

interface IStyleObject {
  color?: string;
}

@Component({
  selector: 'mgi-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextComponent implements OnChanges {
  @Input() type = '';
  @Input() color = '';
  @Input() textTransform = '';
  @Input() textAlign = '';
  @Input() fontWeight: number;
  styleObject: IStyleObject = {};

  constructor() {}

  ngOnChanges(): void {
    this.styleObject = {
      ...this.styleObject,
      ...(this.color && { color: this.color }),
      ...(this.textTransform && { 'text-transform': this.textTransform }),
      ...(this.fontWeight && { 'font-weight': this.fontWeight }),
      ...(this.textAlign && { 'text-align': this.textAlign }),
    };
  }
}
