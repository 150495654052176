// import { routerReducer } from '@ngrx/router-store';
import { reducer as appReducer } from './app.reducer';
import { reducer as authReducer } from './auth.reducer';
import { OrganizationReducer } from './organization.reducer';

export const reducers = {
  app: appReducer,
  auth: authReducer,
  organization: OrganizationReducer,
  // router: routerReducer,
};
